@import "variables";

$navMenuBorder: darken($blue, 5%);
$navMenuSection: darken($blue, 1%);

$navPaddingLeft: 24px;

.navbar {
  background: $navBackground;
  position: fixed;
  left: 0;
  z-index: 30;
  body.fullscreen & {
    display: none; } }

a.nav-item {
  display: block;
  line-height: 30px;
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  position: relative;
  &:last-child {
    border: none; }
  &:hover {
    opacity: 1; }
  .navbar--left &.nav-item--active {
    // text-decoration: underline
    // text-decoration-color: rgba($orange, .8)
    box-shadow: inset 3px 0 0 $orange;
    background: $navHighlight;
    .navbar--bottom & {
      box-shadow: inset 0 -2px 0 $orange; } }
  .navbar-upgrade &,
  .navbar-upgrade & i.fa {
    color: $yellow; }
  span {
    display: inline-block;
    color: rgba(#fff, .5);
    margin-left: 5px;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    height: 46px;
    line-height: 46px;
    top: 0;
    .breakpoint-under-medium & {
      display: none; } } }

.nav-section {
  padding: 0 14px 0 $navPaddingLeft;
  margin-top: 16px;
  // border-bottom: 1px solid $navMenuBorder
  // background: $navMenuSection
  &:first-child {
    margin: 0; }
  span {
    display: block;
    color: rgba(#fff, .5);
    font-size: 10px;
    line-height: 30px;
    // font-weight: 700
    text-transform: uppercase;
    letter-spacing: .6px; } }

.navbar--left {
  top: 0;
  width: $navWidth;
  height: 100%;
  // padding-top: 10px
  overflow: auto;
  // border-right: 1px solid $navMenuBorder
  a.nav-item {
    padding: 0px 20px 0 $navPaddingLeft;
    // border-bottom: 1px solid $navMenuBorder
    &:hover {
      background: $navHighlight; }
    &:last-child {
      border: none; }
    &.nav-item--icon {
      padding: 8px 14px 8px $navPaddingLeft; } }
  &.navbar--upgrade {
    padding-bottom: 50px; } }

.nav-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin-right: 8px;
  position: relative;
  top: 0px; }

.navbar--bottom {
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 2147483002;
  a.nav-item {
    padding: 10px 6px;
    flex-grow: 1;
    .breakpoint-under-small & {
      padding: 10px 4px;
      font-size: 13px; } }
  a.nav-item--mobile-icon {
    flex-grow: 0;
    width: 40px;
    i.fa {
      color: rgba(#000, .3); } }
  .navbar-menu {
    display: flex;
    border-bottom: 1px solid $navMenuBorder; } }

.navbar--bottom .navbar-menu a.nav-item {
  padding: 10px 6px;
  flex-grow: 1;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: .6px;
  font-weight: 700;
  height: 60px;
  line-height: 20px;
  i.fa {
    display: block;
    margin: 0 auto;
    font-size: 14px;
    color: rgba(#fff, .5);
    line-height: 20px; }
  &.nav-item--active {
    background: $navHighlight;
    box-shadow: inset 0 -1px 0 $yellow; } }

.navbar--dropdown {
  padding-right: 60px; }

.navbar-dropdown-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 61px;
  color: $yellow;
  display: flex;
  text-decoration: none;
  border-bottom: 1px solid $navMenuBorder;
  i.fa {
    display: block;
    margin: auto; }
  &:hover {
    opacity: 1; } }

.navbar-dropdown {
  position: absolute;
  bottom: calc(100% + 6px);
  right: 4px;
  display: none;
  z-index: 29;
  background: #fff;
  border-radius: 6px;
  @include shadow(6);
  border: 1px solid $borderLighterBlue;
  width: 180px;
  a.nav-item {
    padding: 7px 15px;
    border-right: none;
    text-align: left;
    font-weight: 500;
    line-height: 20px;
    color: $fontColor;
    margin: 3px;
    &:hover,
    &.nav-item--active {
      background: $pageBackground; }
    .breakpoint-under-small & {
      padding: 7px 10px;
      font-size: 13px; } } }

.navbar-dropdown--open {
  display: block; }

.navbar-upgrade {
  margin-top: 20px; }

@keyframes navLoader {
  0% {
    background-position: 100% 0; }
  100% {
    background-position: -100% 0; } }

.nav-item-placeholder {
  height: 30px;
  display: block;
  width: 100%;
  padding-top: 5px;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background: linear-gradient(to right, rgba(#fff, .3) 10%, rgba(#fff, .4) 40%, rgba(#fff, .3) 70%);
    background-size: 200%;
    background-position: 100% 0;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: navLoader;
    animation-timing-function: linear; } }

.navbar-logo {
  display: none; }

.breakpoint-over-medium .navbar-logo {
  display: flex;
  height: $headerHeight;
  a {
    // margin: auto 0 auto $navPaddingLeft
    margin: auto;
    &:hover {
      opacity: 1; } }
  svg {
    width: 100%;
    height: 28px;
    margin: auto; } }
