.button {
  display: inline-block;
  line-height: 18px;
  padding: 10px 16px;
  border-radius: 4px;
  background: $tmblue;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border: none;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  &:hover {
    opacity: .8;
    color: #fff; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  i.fa, .icon {
    margin-right: 6px;
    margin-left: -4px;
    font-size: 12px;
    color: $orangeLight; }
  &[disabled] {
    opacity: .6;
    cursor: default; } }

.button--orange {
  background: $orange;
  i.fa {
    color: #fff; } }

.button--red {
  background: tomato;
  // box-shadow: inset 0 0 0 1px rgba(#000, .15)
  text-shadow: 1px 1px 1px rgba(#000, .1);
  i.fa {
    color: rgba(#000, .4); }
  &.button--icon i.fa {
    color: #fff; } }

.button--green {
  background: $green2;
  // box-shadow: inset 0 0 0 1px rgba(#000, .15)
  text-shadow: 1px 1px 1px rgba(#000, .1);
  i.fa {
    color: rgba(#000, .2); }
  &.button--icon i.fa {
    color: #fff; } }

.button--yellow {
  background: $yellow;
  box-shadow: inset 0 0 0 1px rgba(#000, .15);
  text-shadow: 1px 1px 1px rgba(#000, .1);
  i.fa {
    color: rgba(#000, .2); } }

.button--grey {
  background: $pageBackground;
  color: $fontColor;
  i.fa {
    color: rgba(#000, .4); }
  &:hover {
    color: $fontColor;
    opacity: 1;
    background: $pageBackgroundDark; } }

.button--white {
  background: #fff;
  color: $fontColor;
  @include shadow(4, rgba($blue, .06));
  i.fa {
    color: $blue; }
  &:hover {
    @include shadow(6, rgba($blue, .06));
    opacity: 1;
    color: $blue; } }

.button--ghost {
  background: #fff;
  color: $fontColor;
  box-shadow: inset 0 0 0 1px $borderLighterBlue;
  i.fa {
    color: $fontColorLight; }
  &:hover {
    color: $fontColor;
    box-shadow: inset 0 0 0 1px $borderLightBlue; } }

.button--ghost-yellow {
  background: #fff;
  color: $fontColor;
  box-shadow: inset 0 0 0 1px $yellow;
  i.fa {
    color: $yellow; }
  &:hover {
    color: $fontColor;
    box-shadow: inset 0 0 0 1px $yellow; } }

.button-overlay-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($blue, .7);
  display: flex;
  i.fa {
    margin: auto;
    display: block; }
  .button--orange & {
    background: rgba($orange, .7); } }

a.button:hover {
  color: #fff; }

a.button--grey:hover,
a.button--white:hover,
a.button-plain:hover,
a.button--plain:hover {
  color: $fontColor; }

.button--small {
  padding: 5px 12px;
  font-size: 12px;
  border-radius: 3px;
  i.fa {
    margin-right: 6px;
    font-size: 10px; }
  &.button--icon {
    padding: 5px 0;
    width: 28px;
    text-align: center; } }

.button--icon-right i.fa {
  margin-right: -4px;
  margin-left: 6px; }

.button--icon-right.button--small i.fa {
  margin-right: -4px;
  margin-left: 6px; }

.button--tiny {
  padding: 3px 10px;
  font-size: 10px;
  i.fa {
    margin-right: 6px;
    font-size: 10px; } }

.button-plain,
.button--plain {
  background: none;
  border: none;
  padding: 0;
  color: $fontColorLight;
  border-radius: 0;
  text-decoration: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); }
  &:hover {
    color: $fontColor; }
  i.fa {
    font-size: 12px;
    margin-right: 6px;
    position: relative;
    top: -1px; } }

.button-plain--small,
.button-plain-small {
  font-size: 12px;
  font-weight: 500; }

.button-plain-red:hover {
  color: tomato; }

.button-square {
  display: inline-block;
  background: #fff;
  @include shadow(3);
  border-radius: 6px;
  border: 1px solid $borderLighterBlue;
  line-height: 20px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  color: $fontColor;
  &:hover {
    border-color: $blue; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px $blue;
    border-color: $blue; }
  i.fa {
    font-size: 10px;
    color: $fontColorLight;
    margin-right: 6px;
    position: relative;
    top: -1px; }
  &[disabled] {
    opacity: .6;
    cursor: default;
    &:hover {
      border-color: $borderLightGrey; } } }

.button--icon {
  i.fa {
    margin: 0; } }
