.term-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, .6);
  display: flex;
  z-index: 2147483003;
  padding: 20px;
  .breakpoint-under-small & {
    padding: 0; } }

.term-overlay-inner {
  width: 100%;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 5px 20px rgba(#000, .1);
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  max-height: 800px;
  .breakpoint-under-small & {
    height: 100%;
    max-height: 100%; } }

.term-overlay-action {
  padding: 20px;
  text-align: center;
  .form-item {
    padding-bottom: 10px; } }

.term-overlay-header {
  padding: 20px;
  h2 {
    font-size: 22px;
    font-weight: 300;
    margin: 0 0 5px; }
  p {
    margin: 0;
    color: darken($fontColorLight, 10%); } }

.term-overlay-content {
  padding: 20px;
  background: $lightGrey;
  border-top: 1px solid $borderLightGrey;
  border-bottom: 1px solid $borderLightGrey;
  box-shadow: inset 0 3px 10px rgba(#000, .1);
  overflow: auto;
  flex-grow: 1; }
