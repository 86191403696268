@charset "UTF-8";

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none; }
  &.dragging {
    cursor: pointer;
    cursor: hand; } }

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: "";
    display: table; }

  &:after {
    content: "";
    display: table;
    clear: both; } }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }

[dir=rtl] .slick-slide {
  float: right; }

.slick-slide {
  img {
    display: block; }

  &.slick-loading img {
    display: none; }

  &.dragging img {
    pointer-events: none; }
  &:focus,
  > div:focus,
  > div > div:focus {
    outline: none; } }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f104" !default;
$slick-next-character: "\f105" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  z-index: 4;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  background: $blue;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(#000, .1);
  &:hover, &:focus {
    outline: none;
    color: transparent;
    opacity: .8; }
  &.slick-disabled {
    opacity: 0; }
  &:before {
    @extend %fa;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; } }

.slick-prev {
  left: -10px;

  [dir="rtl"] & {
    left: auto;
    right: -10px; }

  &:before {
    content: $slick-prev-character;

    [dir="rtl"] & {
      content: $slick-next-character; } }
  .breakpoint-under-small & {
    left: 0; } }


.slick-next {
  right: -10px;

  [dir="rtl"] & {
    left: -10px;
    right: auto; }

  &:before {
    content: $slick-next-character;

    [dir="rtl"] & {
      content: $slick-prev-character; } }
  .breakpoint-under-small & {
    right: 0; } }


/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    width: 60px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: none;
      display: block;
      height: 20px;
      width: 100%;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer;
      &:hover, &:focus {
        outline: none; }
      &:before {
        content: " ";
        display: block;
        width: 100%;
        height: 4px;
        background: $blue;
        opacity: .3; } } } }

.slick-dots li button:hover,
.slick-dots li.slick-active button {
  &:before {
    opacity: 1; } }

.breakpoint-under-small .slick-dots li {
  width: 10px;
  button:before {
    width: 6px;
    height: 6px;
    border-radius: 50%; } }
