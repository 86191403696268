.forbidden-page {
  text-align: center;
  padding: 160px 20px; }

.forbidden-title {
  font-size: 26px;
  font-weight: 300; }

.forbidden-content {
  padding-top: 15px; }
