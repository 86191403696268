.courses-group {
  margin: 0 0 40px; }

.courses-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  margin: 0 0 10px; }

.courses-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  .breakpoint-under-small & {
    margin: 0; } }

.courses-list-item {
  display: block;
  width: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px;
  .courses-group--guide & {
    padding-top: 60px; }
  .breakpoint-under-medium & {
    width: 50%; }
  .breakpoint-under-small & {
    width: 100%; } }

.courses-list-item-inner {
  text-decoration: none;
  background: #fff;
  display: block;
  box-shadow: 0 2px 10px rgba(#000, .07);
  color: $fontColor;
  img {
    display: block;
    width: 100%;
    height: 147px;
    object-fit: cover; }
  .courses-group--guide & {
    position: relative;
    padding-top: 145px;
    img {
      position: absolute;
      top: -50px;
      left: 0;
      width: 100%;
      height: 200px;
      object-fit: contain; } } }


.courses-list-item-content {
  padding: 15px; }

.courses-list-item-name {
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  min-height: 54px;
  .courses-group--guide & {
    border-top: 1px dashed $borderLighterGrey;
    padding-top: 10px;
    min-height: 0; } }

.course-page {
  padding: 30px 20px 60px; }

.course-intro {
  margin-top: 30px; }

.course-intro-content {
  padding: 40px;
  background: #fff;
  position: relative;
  padding-right: 230px;
  margin-right: 345px;
  min-height: 500px;
  .breakpoint-under-medium & {
    padding: 0;
    margin: 0;
    min-height: 0;
    .styled-content {
      padding: 20px 20px 0; } } }

.course-intro-media--image {
  position: absolute;
  height: 100%;
  top: 0;
  left: 100%;
  margin-left: -200px;
  width: 345px + 200px;
  object-fit: contain;
  .breakpoint-under-medium & {
    position: static;
    margin: 0;
    width: 100%; } }

.course-intro-media--video {
  position: absolute;
  left: 100%;
  top: 100px;
  margin-left: -160px;
  width: 345px + 160px;
  box-shadow: 0 2px 20px rgba(#000, .1);
  .breakpoint-under-medium & {
    position: static;
    margin: 0;
    width: 100%; } }

.course-intro-action {
  .breakpoint-under-medium & {
    padding: 20px; } }

.course-breadcumb-divider {
  padding: 0 5px;
  opacity: .5; }

.courses-list-item-info {
  display: flex;
  margin-top: 10px;
  font-size: 13px; }

.courses-list-item-cta {
  color: $orange;
  flex-grow: 1;
  i.fa {
    margin-right: 3px;
    font-size: 9px;
    position: relative;
    top: -1px;
    opacity: .6; } }

.courses-list-item-cta--blue {
  color: $blue; }

.courses-list-item-cta--grey {
  color: $fontColorLight; }

.courses-list-item-info-duration {
  color: $fontColorLight; }

/**
 * Main
 */

.course-main {
  background: #fff; }

.course-main-header {
  padding: 15px;
  display: flex;
  h2 {
    margin: 0 0 5px;
    color: $fontColor;
    font-weight: 400;
    font-size: 26px;
    line-height: 1.37em;
    flex-grow: 1; } }

.course-main-header-info {
  color: $fontColorLight;
  font-size: 13px;
  display: flex;
  span {
    display: block;
    margin: auto; } }

.course-main-video {
  display: flex;
  border-bottom: 1px solid $borderLighterGrey;
  flex-direction: row-reverse;
  .breakpoint-under-small & {
    display: block; } }

.course-main-playlist {
  width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  background: #333;
  position: relative;
  .breakpoint-under-small & {
    width: 100%;
    max-height: 300px;
    overflow: auto; }
  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(0, rgba(#333, 1), rgba(#333, 0));
    pointer-events: none; } }

.course-main-playlist-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-bottom: 30px;
  .breakpoint-under-small & {
    position: static;
    max-height: 300px; } }

.course-main-playlist-item {
  display: block;
  width: 100%;
  background: none;
  border: none;
  padding: 15px;
  border-bottom: 1px solid #222;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orange, .8); }
  &:hover {
    background: #444; } }

.course-main-playlist-item--active {
  background: #555; }

.course-main-playlist-item--completed {
  opacity: .5;
  text-decoration: line-through; }

.course-main-player {
  flex-grow: 1;
  position: relative;
  .course-main--video-multi {
    flex-grow: 0;
    width: calc(100% - 300px); }
  .video-js {
    width: 100%; }
  video {
    &:focus {
      outline: none; } } }

.course-main-player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(#333333, .8);
  color: #fff;
  text-align: center; }

.course-main-player-overlay-inner {
  margin: auto; }

.course-main-content-step-inner {
  padding: 30px; }

.course-main-player-overlay-next {
  margin-bottom: 15px;
  font-size: 20px; }

.course-countdown {
  margin-top: 15px;
  font-size: 12px;
  opacity: .7; }

.course-countdown-cancel {
  margin-top: 5px; }

.course-countdown-cancel-button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: rgba(#fff, .5);
  &:hover {
    color: #fff; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $orange; } }

.course-main-player-overlay-complete {
  h3 {
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 300; }
  p {
    margin: 0 0 20px; } }
