@import "variables";

.page--auth-loader {
  height: 100%;
  background: $pageBackground;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.page--auth-loader {
  .navbar-menu {
    .nav-item--fake:nth-child(2) {
      opacity: .8;
      .nav-item-placeholder:before {
        animation-delay: .2s; } }
    .nav-item--fake:nth-child(3) {
      opacity: .6;
      .nav-item-placeholder:before {
        animation-delay: .4s; } }
    .nav-item--fake:nth-child(4) {
      opacity: .4;
      .nav-item-placeholder:before {
        animation-delay: .6s; } } }
  .navbar--bottom .nav-item--fake:nth-child(4) {
    display: none; }
  .navbar--bottom .navbar-menu .nav-item--fake {
    opacity: .4; } }
