.loader,
.loader-small {
  display: flex;
  height: 300px;
  i.fa,
  i.fas {
    display: block;
    margin: auto;
    font-size: 20px;
    color: $green2;
    width: 20px;
    height: 20px;
    line-height: 20px; } }

.loader-small {
  height: 60px; }

.fullpage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: $lightGrey; }

.fullpage-inner {
  margin: auto;
  text-align: center;
  i.fa {
    font-size: 30px;
    color: $orangeLight; } }

.fullpage-text {
  margin-top: 20px; }

.fullpage-links {
  margin-top: 20px; }
