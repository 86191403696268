.subscriptions-plans {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }

.subscriptions-plan {
  flex-shrink: 0;
  padding: 20px;
  margin: 10px;
  max-width: 100%;
  border: 1px solid $borderLightGrey;
  background-color: darken(#fff, 2%);
  background-image: linear-gradient(rgba($lighterGrey, .95), rgba($lighterGrey, .95)), url('https://d2nce6johdc51d.cloudfront.net/img/banner.svg');
  background-size: 190px;
  background-position: 50% 50%;
  box-shadow: inset 1px 1px 3px rgba(#000, .07);
  border-radius: 2px;
  flex-grow: 1; }

.subscriptions-plan-type {
  color: #f9a51a;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px; }

.subscriptions-plan-active {
  font-size: 16px;
  margin-bottom: 10px; }
