.form-field-file {
  display: block;
  background: $pageBackgroundDark;
  border-radius: 6px;
  padding: 1px; }

.form-field-file-upload {
  position: relative;
  height: 42px;
  input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
  .form-item-style--narrow & {
    height: 57px; } }

.form-field-file-upload-text {
  display: inline-block;
  line-height: 26px;
  padding: 0 10px;
  color: $fontColor;
  font-size: 12px;
  font-weight: 500;
  background: #fff;
  @include shadow(3, rgba($blue, .06), true);
  // border: 1px solid $borderLighterBlue
  border-radius: 4px;
  margin: 8px;
  i {
    margin-right: 6px;
    opacity: .6; }
  .form-field-file:hover & {
    box-shadow: 0 0 0 2px rgba($inputBorderBlue, .2); }
  .form-item-style--narrow & {
    display: block;
    padding: 9px 10px 9px 30px;
    position: relative;
    line-height: 16px;
    i.fa {
      display: block;
      position: absolute;
      top: 50%;
      left: 5px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-top: -10px; } } }

.form-field-file-upload-info {
  opacity: .8;
  font-size: 10px;
  .form-item-style--narrow & {
    display: block; } }

.form-field-file-item {
  display: block;
  height: 42px;
  padding: 0 40px;
  position: relative; }

.form-field-file-item-preview {
  width: 40px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='8px' viewBox='0 0 8 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 63.1 (92452) - https://sketch.com --%3E%3Ctitle%3Eimage-background%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='-'%3E%3Cg id='image-background'%3E%3Crect id='Rectangle' fill='%23FFFFFF' x='0' y='0' width='8' height='8'%3E%3C/rect%3E%3Crect id='Rectangle' fill='%23D8D8D8' x='0' y='0' width='4' height='4'%3E%3C/rect%3E%3Crect id='Rectangle-Copy' fill='%23D8D8D8' x='4' y='4' width='4' height='4'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  img {
    display: block;
    width: 40px;
    height: 42px;
    object-fit: cover; } }

.form-field-file-item-preview--icon {
  i.fa {
    display: block;
    background: #fff;
    color: $green2;
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    box-shadow: inset 0 0 0 1px rgba(#000, .1); } }

.form-field-file-item-name {
  flex-grow: 1;
  line-height: 20px;
  padding: 11px 5px;
  font-size: 12px;
  font-weight: 500;
  color: $fontColor;
  position: absolute;
  top: 0;
  left: 40px;
  width: calc(100% - 80px); }

.form-field-file-item-name-size {
  font-size: 10px;
  opacity: .5; }

.form-field-file-item-remove {
  width: 38px;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 5px;
  margin-top: -19px;
  button {
    background: #fff;
    border: none;
    @include shadow(6);
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 6px;
    line-height: 26px;
    color: $borderGrey;
    font-size: 10px;
    &:hover {
      color: $red;
      @include shadow(8); }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $orange; } } }

.form-item-style--simplewide {
  & .form-field-file {
    box-shadow: none;
    background: none;
    border: 0; }
  & .form-field-file-upload-text {
    display: inline-block;
    line-height: 26px;
    padding: 0;
    margin: 0;
    background: $lighterGrey;
    color: $fontColor;
    font-size: 14px;
    font-weight: 400;
    border: 0; }
  & .field-file-upload input {
    display: block; } }

// Box

.form-field-box-file {
  position: relative;
  width: 140px;
  height: 140px; }

.form-field-box-file-preview {
  position: absolute;
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.form-field-box-file-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex; }

.form-field-box-file-icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: $borderLighterGrey;
  border: 1px solid $borderLightGrey;
  border-radius: 50%;
  margin: auto;
  color: $fontColorLight;
  font-size: 20px;
  text-align: center; }

.form-field-box-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0; }
  &:hover {
    background: rgba(#000, .1); } }

.form-field-box-file-remove {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 4px;
  button {
    display: block;
    width: 100%;
    background: none;
    border: none;
    border-radius: 0;
    span {
      display: inline-block;
      line-height: 16px;
      padding: 0 8px;
      background: rgba(#000, .5);
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: .3px;
      color: #fff;
      font-weight: 700;
      border-radius: 8px; }
    &:hover {
      span {
        background: rgba($red, .5); } } } }
