.content-consent-list-wrapper {
  margin-top: 30px;
  .loader {
    height: 60px; } }

.content-consent-list-title {
  margin-bottom: 20px;
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: $blue;
    line-height: 26px; } }
