.article {
  padding: 20px; }

.article-loading {
  padding: 40px;
  text-align: center;
  i.fa {
    color: $orange;
    font-size: 20px; } }

.article-content {
  h2 {
    font-size: 24px;
    line-height: 30px;
    &:first-child {
      font-size: 36px;
      line-height: 40px;
      font-weight: 300;
      margin-top: 0; } }
  img {
    display: block;
    max-width: 100%; }
  figure {
    margin: 0; }
  figcaption {
    text-align: center;
    font-style: italic; }
  blockquote {
    margin: 0 0 20px;
    background: $lightGrey;
    padding: 15px 15px 15px 20px;
    border-left: 2px solid $blue; } }
