.form-success {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex; }

.form-success-inner {
  margin: auto;
  text-align: center; }

.form-success-icon {
  font-size: 100px;
  color: $orangeLight;
  padding-bottom: 20px; }

.form-success-message {
  font-size: 18px; }
