.qr {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0);
  display: flex;
  visibility: hidden;
  overflow: auto;
  &.qr--mounted {
    transition: .3s all ease-in-out; }
  &.qr--open {
    visibility: visible;
    background: rgba(#000, .5); } }

.qr-panel {
  margin: auto;
  background-color: #fff;
  padding: 20px 20px 10px; }


.qr-panel-close {
  padding: 5px 0 0;
  a {
    text-decoration: none;
    color: $fontColor; } }
