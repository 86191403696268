.deleteprompt {}

.deleteprompt-button {
  margin: 5px;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  @extend .report-export-button;
  .survey-link:hover & {
    border-color: $blue; } }

.deleteprompt-question {
  padding-left: 10px; }
