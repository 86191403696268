// Text, icons, hover states
$primary-foreground-color: #fff !default;

// Control backgrounds (control bar, big play, menus)
$primary-background-color: #2B333F !default;
$primary-background-transparency: 0.7 !default;

// Hover states, slider backgrounds
$secondary-background-color: lighten($primary-background-color, 33%) !default;
$secondary-background-transparency: 0.5 !default;

// Avoiding helvetica: issue #376
$text-font-family: Arial, Helvetica, sans-serif !default;

// Using the '--' naming for component-specific styles
$big-play-button--border-size: 0.06666em !default;
$big-play-button--width: 3em !default;
$big-play-button--line-height: 1.5em !default;
$big-play-button--height: $big-play-button--line-height + ($big-play-button--border-size * 2) !default;
$big-play-button--transparency: 0.8 !default;
