.approve-page {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $lightGrey; }

.approve-page--loading {
  display: flex; }

.approve-loader {
  width: 40px;
  height: 40px;
  margin: auto;
  i.fa {
    display: block;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: $green2;
    font-size: 30px; } }

.approve-panel {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $borderLightGrey;
  border-right: 1px solid $borderLightGrey;
  .approve-page--material-open & {
    border-left: none;
    width: 400px;
    flex-shrink: 0; } }

.approve-panel-header {
  padding: 30px 20px; }

.approve-panel-header-logo {}


.approve-panel-header-info {
  max-width: 400px;
  margin: 0 auto;
  text-align: center; }


.approve-panel-header-info {}

.approve-panel-list {
  flex-grow: 1; }

.approve-materials-list {
  max-width: 600px;
  margin: 0 auto; }

.approve-materials-empty {
  max-width: 600px;
  margin: 30px auto;
  text-align: center;
  color: $fontColorLight; }

.approve-materials-list-title {
  display: block;
  margin-bottom: 10px;
  font-size: 18px; }


.approve-materials-list-group {
  padding-bottom: 10px; }

.approve-materials-list-group-button {
  display: flex;
  text-align: left;
  width: 100%;
  border: none;
  padding: 10px;
  background: none;
  border: 1px solid $borderLightGrey;
  border-radius: 4px;
  background: #fff;
  &:hover {
    border-color: $tmblue; }
  &:focus {
    outline: none; } }

.approve-materials-list-group-button-icon {
  padding-right: 10px;
  padding-top: 4px;
  display: flex;
  height: 100%;
  span {
    display: block;
    width: 36px;
    height: 36px;
    line-height: 34px;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    border: 1px solid $borderLightGrey;
    color: $tmblue;
    .approve-materials-list-group-button--approve-yes & {
      color: $green2;
      border: 1px solid $green2;
      background: rgba($green2, .1); }
    .approve-materials-list-group-button--approve-no & {
      color: $red;
      border: 1px solid $red;
      background: rgba($red, .1); } }
  i.fa {
    font-size: 14px; } }

.approve-materials-list-group-button-content {
  b {
    display: block;
    font-size: 18px;
    line-height: 25px; }
  span {
    color: #999;
    line-height: 20px; } }


.approve-material {
  margin-bottom: 20px; }

.approve-material-status {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin: auto; }

.approve-material-status--approved {
  background: $green2; }

.approve-material-status--notapproved {
  background: $red; }

.approve-materials-list-item-name {
  font-weight: 500;
  margin-bottom: 6px; }

.approve-materials-list-item-info {
  color: #999;
  font-size: 12px; }

.approve-materials-list {
  flex-grow: 1;
  overflow: auto; }


.approve-materials-list-submit {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding-top: 5px;
  display: block; }

.approve-preview-list {
  max-width: 900px;
  margin: 0 auto; }

.approve-material-form-inner {
  width: 100%; }

.material-preview-item {
  width: 100%;
  video {
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 10px 20px rgba(#000, .2);
    background: #333; } }

.material-preview-item--image {
  img {
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 100vh;
    box-shadow: 0 10px 20px rgba(#000, .2);
    background: #333; } }

@keyframes commentHighlight {
  0% {
    box-shadow: 0 3px 5px rgba($orange, .2); }
  50% {
    box-shadow: 0 3px 20px rgba($orange, .5); }
  100% {
    box-shadow: 0 3px 5px rgba($orange, .2); } }

.approve-material-form {
  padding-top: 20px;
  textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: 2px solid $borderLightGrey;
    border-radius: 2px;
    padding: 6px;
    font-size: 14px;
    height: 100px;
    background: #fff;
    box-shadow: none;
    &:focus {
      outline: none;
      border-color: $tmblue; }
    .highlight-comments & {
      border-color: $orange;
      animation: .8s ease-in-out infinite commentHighlight; } } }

.material-preview-article-content {
  background: #fff;
  padding: 30px;
  max-width: 780px;
  box-shadow: 0 6px 20px rgba(#000, .1);
  margin: 0 auto;
  .breakpoint-under-small & {
    padding: 10px; } }

.material-preview-article-content {
  font-size: 16px;
  line-height: 26px;
  img {
    max-width: 100%;
    display: block;
    width: 100%; }
  p {
    margin: 0 0 30px; }
  h1 {
    margin: 0 0 30px;
    font-size: 36px;
    line-height: 42px; }
  h2 {
    margin: 30px 0 10px;
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    &:first-child {
      margin-top: 0; } }
  figure {
    width: 100%;
    margin: 0; }
  figcaption {
    font-style: italic;
    text-align: center;
    padding: 5px 0 15px; }
  blockquote {
    background: $lightGrey;
    border-left: 4px solid $orange;
    margin: 0 0 30px;
    padding: 10px 10px 10px 20px;
    p {
      margin: 0 10px;
      &:last-child {
        margin: 0; } } } }



/* Mobile update */

.approve-header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; }

.approve-header-container {
  max-width: 600px;
  margin: 0 auto;
  background: rgba(#fff, .95);
  border: 1px solid rgba($borderLightGrey, .6);
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 66px;
  box-shadow: 0 2px 20px rgba(#000, .05);
  display: flex;
  position: relative;
  .breakpoint-under-small & {
    height: 52px; } }

.approve-header-validation-error {
  position: absolute;
  top: calc(100% - 5px);
  background: tomato;
  color: #fff;
  line-height: 16px;
  padding: 5px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0 4px 20px rgba(#000, .1);
  text-align: center;
  width: calc(100% - 10px);
  max-width: 580px;
  left: 50%;
  transform: translateX(-50%);
  &:after {
    content: " ";
    border: 6px solid transparent;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-color: tomato; } }

.approve-header-logo {
  text-align: center;
  flex-grow: 1;
  padding: 15px;
  img {
    height: 35px; }
  .breakpoint-under-small & {
    padding: 8px; } }

.approve-header-container-action--center {
  flex-grow: 1;
  text-align: center; }

.approve-header-container-action--left,
.approve-header-container-action--right {
  flex: 0 0 80px;
  button {
    display: block;
    width: 100%;
    height: 65px;
    border: none;
    background: none;
    padding: 0;
    color: $tmblue;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($orangeLight, .9); }
    span {
      display: inline-block;
      border: 1px solid;
      color: $borderGrey;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: .3px;
      padding: 0 7px;
      line-height: 20px;
      border-radius: 11px; }
    &:hover {
      span {
        color: $tmblue; } } }
  .breakpoint-under-small & {
    flex: 0 0 65px;
    button {
      height: 52px; } } }

// .approve-header-container-action--left button
//   border-right: 1px solid rgba($borderLightGrey, .6)

// .approve-header-container-action--right button
//   border-left: 1px solid rgba($borderLightGrey, .6)


.approve-header-status-wrapper {
  display: flex;
  max-width: 140px;
  margin: 15px auto;
  height: 35px;
  .breakpoint-under-small & {
    margin: 8px auto; } }

.approve-header-status {
  flex-grow: 1;
  display: block;
  background: none;
  border: none;
  padding: 0;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); } }

.approve-header-status-icon {
  width: 35px;
  height: 35px;
  line-height: 33px;
  border-radius: 50%;
  border: 1px solid $borderLightGrey;
  font-size: 14px;
  color: $tmblue;
  display: block;
  margin: 0 auto;
  .approve-header-status--active & {
    color: $tmblue;
    border: 1px solid $tmblue;
    background: rgba($tmblue, .1); }
  .approve-header-status--approve-yes & {
    color: $green2;
    border: 1px solid $green2;
    background: rgba($green2, .1); }
  .approve-header-status--approve-no & {
    color: $red;
    border: 1px solid $red;
    background: rgba($red, .1); } }

.approve-header-notification-wrapper {
  display: flex;
  width: 100%;
  height: 100%; }

.approve-header-notification {
  background: #fff;
  line-height: 12px;
  padding: 10px 10px 10px 6px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0 2px 6px rgba(#000, .1);
  margin: auto;
  display: inline-block;
  border: 1px solid $borderLightGrey;
  i.fa {
    color: tomato;
    margin-right: 6px; }
  .breakpoint-under-small & {
    padding: 7px 10px; }
  .breakpoint-under-minimum & {
    width: 180px;
    margin-top: -3px; } }

.approve-content {
  padding-top: 66px + 20px + 20px;
  padding-bottom: 60px + 20px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
  height: 100%;
  .breakpoint-under-small & {
    padding-top: 52px + 20px + 20px;
    padding-bottom: 120px; } }

.approve-message {
  padding: 10px;
  max-width: 600px;
  margin: 0 auto 20px;
  background: #fff;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid $borderLightGrey;
  border-radius: 3px;
  i.fa {
    margin-right: 8px;
    color: rgba(#000, .3); } }

.approve-message--info {
  border: 1px solid $blue; }

.approve-message--error {
  background: tomato;
  border: 1px solid darken(tomato, 5%);
  color: #fff;
  text-shadow: 1px 1px 3px rgba(#000, .1); }

.approve-message--success {
  background: $green2;
  border: 1px solid darken($green2, 5%);
  color: #fff;
  text-shadow: 1px 1px 3px rgba(#000, .1); }

.approve-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  // background: linear-gradient(0deg, rgba($lightGrey, 1), rgba($lightGrey, 0))
  // background: rgba($lightGrey, .95)
  background: #fff;
  border-top: 1px solid $borderLightGrey; }

.approve-footer-container {
  max-width: 520px;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  position: relative;
  .breakpoint-under-small & {
    padding: 8px; } }


// .approve-footer-action-button
//   display: inline-block
//   line-height: 20px
//   padding: 7px 17px
//   border-radius: 20px
//   background: $tmblue
//   color: #fff
//   font-weight: 800
//   font-size: 13px
//   letter-spacing: .3px
//   border: none
//   i.fa
//     color: $yellow
//     margin-right: 8px
//   &:hover
//     opacity: .8
//   &:focus
//     outline: none

.approve-footer-action-inner {
  display: flex; }

.approve-footer-action-center {
  flex-grow: 1;
  text-align: center;
  > div {
    padding: 8px;
    line-height: 20px; } }

.approve-footer-action-button {
  display: inline-block;
  margin: 0 5px;
  border: none;
  background: none;
  padding: 8px 17px;
  font-size: 14px;
  line-height: 20px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); }
  .breakpoint-under-small & {
    margin: 5px; } }

.approve-footer-action-button--yes {
  background: $green2;
  border-radius: 18px;
  color: #fff;
  font-weight: 500;
  i.fa {
    margin-right: 6px;
    font-size: 12px;
    margin-left: -3px; }
  &:hover {
    opacity: .8; } }

.approve-footer-action-button--next,
.approve-footer-action-button--start,
.approve-footer-action-button--submit,
.approve-footer-action-button--fix,
.approve-footer-action-button--next-error {
  background: $tmblue;
  border-radius: 18px;
  color: #fff;
  font-weight: 500;
  i.fa {
    margin-left: 6px;
    font-size: 12px;
    margin-right: -3px;
    color: $orangeLight;
    position: relative;
    top: -1px; }
  &:hover {
    opacity: .8; } }

.approve-footer-action-button--next-error {
  background: tomato;
  i.fa {
    color: #fff; } }

.approve-footer-action-button--start i.fa,
.approve-footer-action-button--fix i.fa,
.approve-footer-action-button--submit i.fa, {
  margin-right: 6px;
  margin-left: -2px; }

.approve-footer-action-button--no {
  background: tomato;
  color: #fff;
  border-radius: 18px;
  font-weight: 500;
  &:hover {
    opacity: .8; } }

.approve-footer-action-button--cancel {
  color: $fontColorLight;
  &:hover {
    color: $fontColor; } }

.approve-admin-preview {
  height: 100%;
  overflow: auto;
  .approve-material {
    padding-bottom: 30px; } }

.approve-admin-preview-inner {
  max-width: 900px;
  margin: auto; }

.approve-admin-preview-group-title {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px 20px 10px;
  font-size: 20px; }

.approve-admin-preview-comment {
  background: #fff;
  padding: 15px;
  margin-top: 15px;
  border: 2px solid $borderLightGrey;
  border-radius: 3px;
  b {
    display: block;
    margin-bottom: 5px; } }

.approve-submitted-message {
  max-width: 600px;
  margin: 0 auto;
  padding: 120px 20px; }

.approve-submitted-message-icon {
  span {
    display: flex;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    color: #fff;
    border-radius: 60px;
    background: $green2;
    margin: 0 auto 30px;
    i.fas {
      font-size: 50px;
      display: block;
      margin: auto; } } }

.approve-submitted-message-content {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  line-height: 30px;
  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 22px; }
  p {
    margin: 10px 0;
    color: $fontColorLight;
    line-height: 20px; }
  a {
    color: $tmblue; } }

.approve-languages {
  display: block;
  max-width: 600px;
  margin: 0 auto 15px;
  text-align: center; }

.approve-languages-inner {
  display: inline-block;
  background: rgba(#000, .02);
  border: 1px solid rgba(#000, .07);
  padding: 5px 10px;
  border-radius: 2px;
  i.fas {
    color: $borderGrey;
    font-size: 12px;
    margin-right: 10px;
    position: relative;
    top: -1px; } }

.approve-languages-item {
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  margin: 0 6px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px dashed transparent;
  border-radius: 0;
  &:hover {
    border-color: $orange; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba($orangeLight, .9); } }

.approve-languages-item--active {
  border-color: $orange; }

.approve-feedback {
  max-width: 600px;
  margin: 30px auto 0;
  label {
    display: block;
    cursor: pointer;
    margin-bottom: 10px; }
  textarea {
    display: block;
    width: 100%;
    height: 100%;
    border: 2px solid $borderLightGrey;
    border-radius: 2px;
    padding: 6px;
    font-size: 14px;
    height: 100px;
    &:focus {
      outline: none;
      border-color: $tmblue; } } }


.approve-lang-switch {
  max-width: 600px;
  margin: 0 auto 10px;
  .language-switcher {
    display: inline-block;
    .language-switcher-list {
      left: 0;
      right: auto; } } }

.approve-consent {
  max-width: 600px;
  margin: 0 auto 20px; }

.approve-consent-checkbox {
  text-align: left;
  display: flex;
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid $borderLighterGrey;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border: 1px solid $borderLightGrey; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow;
    border-color: $yellow; } }

.approve-consent-checkbox-icon-wrapper {
  flex: 0 0 18px;
  display: flex;
  height: 100%; }

.approve-consent-checkbox-icon {
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  border: 1px solid $borderLightGrey;
  background: $lightGrey;
  text-align: center;
  margin: auto;
  i.fa {
    font-size: 9px;
    display: block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    position: relative;
    left: -1px; }
  .approve-consent-checkbox--checked & {
    border-color: darken($green2, 5%);
    background: $green2;
    color: #fff; } }

.approve-consent-checkbox-text {
  display: block;
  padding-left: 8px;
  font-size: 14px; }

.approve-consent-label {
  color: $blue;
  margin: 15px 0 10px;
  font-weight: 500; }
