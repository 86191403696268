* {
  box-sizing: border-box; }

body {
  //font-family: HelveticaNeue
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  background: #fff;
  height: 100%; }

.ellipsis {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

a {
  color: $blue;
  &:hover {
    opacity: .8; }
  &.link-grey {
    color: $fontColorLight;
    &:hover {
      color: $blue; } } }


.fa-green {
  color: $green2 !important; }

.fa-yellow {
  color: $yellow !important; }

#beacon-container {
  @media screen and (max-width: 900px) {
    .BeaconFabButtonFrame {
      bottom: 60px;
      right: 20px;
      margin-bottom: 0;
      margin-bottom: constant(safe-area-inset-bottom);
      margin-bottom: env(safe-area-inset-bottom); } } }
