@import "variables";

.form-repeater-field {
  margin-bottom: 15px;
  display: flex;
  input[type="text"]:not(.q-input) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    flex-grow: 1; } }

.form-repeater-field-remove {
  flex: 0 0 42px;
  button {
    display: block;
    width: 42px;
    height: 42px;
    background: $lightGrey;
    color: $fontColorLight;
    border: 1px solid $inputBorderGrey;
    border-radius: 0 2px 2px 0;
    font-size: 12px;
    &:hover {
      background: #fff;
      color: tomato; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px tomato; } } }

.form-repeater-utm {
  flex: 1;
  display: flex;
  flex-direction: row;
  .form-field--select {
    flex: 0 1 150px;
    select {
      border-radius: 2px 0 0 2px;
      border-right: none;
      height: 42px; } }
  input[type="text"]:not(.q-input) {
    flex: 1;
    border-radius: 0; } }
