.organization-page {
  background: #f4f4f4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto; }

.organization-box {
  background: #fff;
  margin: 80px auto;
  max-width: 960px;
  padding: 50px 30px;
  position: relative;
  .form-submit-button {
    text-align: center;
    margin: 0 auto; }
  .form {
    max-width: 640px;
    margin: 0 auto; } }

.organization-box-language {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 15px 0 0; }

.organization-box-top {
  img {
    display: block;
    margin: 0 auto 30px;
    width: 160px; } }

.organization-box-header {}


.organization-box-title {
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 20px;
    left: 0;
    background: #e7e7e7;
    width: 100%;
    height: 1px;
    z-index: 2; }
  h1 {
    margin: 0;
    display: block;
    font-size: 16px;
    letter-spacing: .4px;
    display: inline-block;
    background: #fff;
    line-height: 30px;
    padding: 5px 10px;
    position: relative;
    z-index: 3; } }

.organization-box-center {
  text-align: center; }
