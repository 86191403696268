
.survey-analytics {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  position: relative;
  @include shadow(6); }

.survey-analytics-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(#fff, .6);
  i.fa {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    text-align: center;
    color: $yellow;
    margin: auto; } }

.survey-analytics-data {
  display: flex;
  .breakpoint-under-medium & {
    display: block; } }

.survey-analytics-title {
  color: $blue;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  .breakpoint-under-medium & {
    margin: 0; } }

.survey-analytics-panel {
  width: 260px;
  padding-right: 15px;
  .breakpoint-under-medium & {
    width: 100%;
    padding: 0; } }

.survey-analytics-counts {
  .breakpoint-under-medium & {
    display: flex;
    margin: 0 -10px 20px; }
  .breakpoint-under-small & {
    display: block;
    margin: 0; } }

.survey-analytics-count {
  margin-bottom: 30px;
  background: $lighterGrey;
  border: 1px solid $borderLightBlue;
  padding: 10px 10px;
  border-radius: 6px;
  .breakpoint-under-medium & {
    width: 50%;
    margin: 10px; }
  .breakpoint-under-small & {
    width: 100%;
    margin: 10px 0; } }

.survey-analytics-count-number {
  display: block;
  font-weight: 900;
  font-size: 60px;
  line-height: 60px; }

.survey-analytics-count-label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: $blue;
  margin-bottom: 10px; }

.survey-analytics-count-info {
  margin-top: 10px;
  color: $fontColorLight;
  font-size: 14px; }

.survey-analytics-graph {
  flex-grow: 1;
  overflow: hidden;
  .dashboard-nps-dates {
    text-align: right;
    margin-bottom: 20px; } }

.survey-analytics-fake {
  height: 446px; }
