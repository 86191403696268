.global-error {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: tomato;
  color: #fff;
  display: flex;
  opacity: .9; }

.global-error-message {
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 40px 0;
  i.fa {
    display: block;
    font-size: 30px;
    margin: 0 auto 20px;
    color: rgba(#000, .5); }
  span {
    font-size: 18px;
    text-shadow: 1px 1px 3px rgba(#000, .2); } }
