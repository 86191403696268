.language-switcher {
  position: relative;
  font-size: 14px;
  button {
    display: block;
    background: none;
    border: none;
    text-align: left;
    &:focus {
      outline: none; } } }

.language-switcher button.language-switcher-button {
  padding: 0;
  line-height: 20px;
  i.fa {
    margin-right: 6px;
    font-size: 12px;
    color: #d8d8d8;
    position: relative;
    top: -1px; }
  &:hover i.fa {
    color: $blue;
    body.theme-trustmary & {
      color: $tmblue; } } }


.language-switcher-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  box-shadow: 0 2px 12px rgba(#000, .1);
  width: 140px;
  display: none;
  z-index: 10;
  .language-switcher--open & {
    display: block; } }

.language-switcher button.language-switcher-item {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #f4f4f4;
  &:hover {
    background: $lightGrey; }
  &:last-child {
    border: none; } }


.language-switcher button.language-switcher-item--active {
  color: $fontColorLight; }
