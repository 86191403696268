.form-buttons {
  display: flex;
  background: #fff;
  border: 2px solid #ddd;
  padding: 10px 15px;
  line-height: 16px;
  font-size: 14px;
  width: 100%;
  margin-top: -2px;
  color: #222936;
  text-align: left;
  position: relative;
  height: 60px;
  z-index: 1;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow;
    z-index: 2; }
  &:hover {
    outline: none;
    border-color: $yellow;
    z-index: 2; }
  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; } }

.form-buttons--active {
  border-color: $green2;
  z-index: 2; }

.form-buttons-inner {
  width: 100%;
  margin: auto 0; }
