.survey-editor-field--dragging {
  pointer-events: none; }

.survey-editor-step-fields-list {
  position: relative; }

.survey-editor-dropzone {
  text-align: left;
  background: #fff;
  border: 2px dashed $blue;
  color: $blue;
  font-weight: 500;
  border-radius: 3px;
  font-size: 12px;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  line-height: 28px;
  position: absolute;
  top: -1px;
  left: -1px;
  padding-left: 7px;
  z-index: 3;
  opacity: 0;
  .survey-editor-fields-empty > .survey-editor-dropzone-wrapper & {
    position: static; } }

.survey-editor-field--fieldset {
  position: relative;
  z-index: 31; }

.survey-editor-dropzone--active {
  opacity: 1; }
