.settings-page {
  h2 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 300;
    margin: 30px 0 15px; } }

.settings-page-card {
  background: #fff;
  position: relative;
  border-radius: 6px;
  @include shadow(6);
  margin: 0 0 20px;
  .table .table-row > .table-cell:first-child {
    padding-left: 20px; }
  .tabs + & {
    border-top: 0; } }

.settings-page-card-inner {
  padding: 10px 20px 20px; }

.settings-page-card-title {
  padding: 15px 20px 10px;
  border-bottom: 1px solid $borderLighterBlue;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: $fontColor; }
