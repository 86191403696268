body.hide-notifications .notifications {
  display: none; }

.notifications-item {
  display: block;
  padding: 15px 20px;
  background: #fff;
  border-bottom: 1px solid $borderLighterBlue;
  color: $fontColor;
  i.fa {
    margin-right: 6px;
    font-size: 12px; }
  body.disable-header-shadow & {
    border-top: 1px solid $borderLighterBlue;
    border-bottom: none; } }

.notifications-item--info i.fa {
  color: $green2; }

.notifications-item--warning i.fa {
  color: $yellow; }

.notifications-item--error i.fa {
  color: $red; }

.notifications-item-message {
  font-size: 14px; }

.notifications-item-link {
  margin-left: 6px;
  font-size: 14px;
  color: $fontColor;
  text-decoration-color: $yellow; }
