.survey-editor-confirm-popup {
  color: $fontColor; }

.survey-editor-confirm-popup {
  background: #fff;
  padding: 10px;
  display: flex;
  margin: 0 auto; }

.survey-editor-confirm-popup-question {
  font-weight: 500;
  font-size: 12px;
  padding-right: 10px;
  flex-grow: 1; }

.survey-editor-confirm-popup-answer {
  display: inline-block;
  border: none;
  background: none;
  margin: 0;
  font-size: 12px;
  padding: 0;
  border-bottom: 1px dashed $red;
  &:hover {
    color: $red; } }
