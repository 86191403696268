.options {
  background: $pageBackgroundDark;
  padding: 6px;
  border-radius: 6px; }

.options-item {
  z-index: 1001;
  display: flex;
  border: 1px solid $borderLighterBlue;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 5px;
  &:hover {
    border-color: $borderBlue; } }

.options-item--create {
  margin: 0;
  &:hover .options-item-handle {
    color: $blue;
    cursor: pointer; } }

.options-item-handle {
  flex-grow: 0;
  width: 30px;
  color: $fontColorLight;
  text-align: center;
  display: flex;
  cursor: move;
  i.fa {
    font-size: 12px;
    display: block;
    line-height: 12px; }
  &:hover {
    color: $blue; } }

.options-handle {
  margin: auto; }

.form--simple .options-item-input,
.options-item-input {
  flex-grow: 1;
  input[type="text"] {
    border: none;
    background: none;
    padding: 5px 0;
    background: #fff;
    font-size: 14px;
    box-shadow: none;
    padding: 8px;
    line-height: 18px; } }
