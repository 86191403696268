.survey-editor-language-switcher {
  display: flex;
  font-size: 14px; }

.survey-editor-language-switcher-title {
  margin-right: 8px; }

.survey-editor-language-button {
  border: none;
  background: none;
  padding: 0;
  border-bottom: 1px dashed $borderGrey;
  cursor: pointer;
  i.fa {
    margin-right: 5px;
    font-size: 12px;
    color: $fontColorLight; }
  &:hover {
    border-bottom: 1px solid $yellow; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.survey-editor-language-list {
  background: #fff; }

.survey-editor-language-list-item {
  display: block;
  width: 100%;
  border: none;
  background: none;
  border-bottom: 1px solid $borderLightGrey;
  padding: 6px 10px;
  text-align: left;
  &:last-child {
    border: none; }
  &:hover {
    background: $lighterGrey; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.survey-editor-language-list-item--active {
  background: $lighterGrey; }
