.breakpoint-over-small .templates-source {
  margin-bottom: -35px; }

.templates-list {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid lighten($borderLightGrey, 4%);
  background: lighten($borderLighterGrey, 4%);
  border-radius: 3px;
  padding: 45px 15px 15px;
  justify-content: center;
  .breakpoint-under-small & {
    padding: 10px; }
  .templates--embed & {
    background: #fff;
    // padding: 40px 0 0
    border-radius: 0;
    border-left: none;
    border-right: none; } }

.templates-list-item {
  width: 25%;
  padding: 8px;
  display: flex;
  &:hover {
    opacity: 1; }
  .breakpoint-under-medium & {
    width: 50%; }
  .breakpoint-under-small & {
    width: 100%; } }


.templates--embed .templates-list-item {
  width: 25%;
  .breakpoint-under-large & {
    width: 33.33%; }
  .breakpoint-under-medium & {
    width: 50%; }
  .breakpoint-under-small & {
    width: 100%; } }


.templates-item {
  display: block;
  width: 100%;
  box-shadow: 0 2px 6px rgba(#000, .05);
  border-radius: 3px;
  border: 1px solid $borderLightGrey;
  background: #fff;
  display: block;
  color: $fontColor;
  &:hover {
    border-color: $borderLightGrey; }
  &:focus {
    box-shadow: 0 0 0 2px $yellow;
    outline: none; } }

.templates-item--selected {
  border-color: $green2;
  box-shadow: 0 4px 16px rgba($green2, .3);
  &:focus {
    box-shadow: 0 0 0 2px $green2; }
  &:hover {
    border-color: $green2; } }

.templates-item-content {
  display: flex;
  height: 140px;
  .breakpoint-under-small & {
    height: auto;
    padding: 10px; } }

.templates-item-content-inner {
  width: 100%;
  margin: auto; }

.templates-item-title {
  font-size: 16px;
  line-height: 1.3em;
  word-wrap: break-word;
  font-weight: 400; }


.templates-item-description {
  margin-top: 5px;
  font-size: 14px;
  line-height: 16px;
  color: $fontColorLight; }

.templates-empty {
  @extend .responses-empty-inner;
  width: 100%;
  border: none; }


// Embeds

.templates-embed {
  display: block;
  width: 100%;
  flex: 1;
  padding: 0 0 60px;
  background: none;
  border: none;
  border-radius: 3px;
  position: relative;
  &:hover {
    box-shadow: 0 0 0 2px $borderLightGrey; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.templates-embed--selected,
.templates-embed--selected:focus,
.templates-embed--selected:hover {
  box-shadow: 0 0 0 2px $green2; }

.templates-embed-preview {
  display: block;
  width: 100%;
  position: relative;
  min-height: 300px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; } }

.templates-embed-title-wrapper {
  width: 100%;
  padding: 10px;
  height: 50px;
  position: absolute;
  left: 0;
  bottom: 5px; }

.templates-embed-title {
  display: inline-flex;
  height: 30px;
  background: $lighterGrey;
  border: 1px solid $borderLighterGrey;
  border-radius: 2px;
  padding: 0;
  .templates-embed:hover & {
    border-color: $borderLightGrey; } }

.templates-embed-title-check {
  padding: 5px;
  .form-checkbox-icon:not(.form-checkbox-icon--checked) {
    background: #fff; } }

.templates-embed-title-name {
  line-height: 18px;
  max-width: 200px;
  padding: 5px 10px 5px 0;
  font-size: 13px;
  font-weight: 400;
  color: $fontColor; }
