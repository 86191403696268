.remove-prompt {
  padding-top: 10px;
  color: $fontColorLight;
  button {
    display: inline-block;
    padding: 0;
    border: none;
    background: none;
    border-bottom: 1px dashed $borderGrey;
    &:hover {
      border-color: $red; }
    &:focus {
      outline: none; } } }

.remove-prompt--question {}

.remove-prompt-question {
  color: $fontColor; }

.remove-prompt--open {}

.remove-prompt--loading {}
