.list {
  background: #fff;
  box-shadow: $defaultBoxShadow;
  .dashboard & {
    box-shadow: none; } }

.list-item {
  color: $fontColor;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #f4f4f4;
  padding: 15px 20px;
  &:last-child {
    border: none; }
  &:hover {
    .list-item-name {
      color: $blue; } }
  body.theme-trustmary &:hover .list-item-name {
    color: $tmblue; }
  .list--small & {
    padding: 10px 0; } }

.list-item-name {
  display: block;
  font-size: 18px;
  .breakpoint-under-medium & {
    font-size: 16px; }
  .list--small & {
    font-size: 16px; } }

.list-item-info {
  padding-top: 7px;
  font-size: 12px;
  max-width: 100%;
  overflow: hidden;
  opacity: .7;
  b {
    margin-right: 3px;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-size: 10px; }
  span {
    margin-right: 10px; }
  .list--small & {
    padding-top: 5px; } }

.list-actions {
  padding: 20px;
  background: #fff;
  border-top: 1px solid #f4f4f4;
  text-align: center; }


/* List grid */
