.form {
  position: relative; }

.form-lazy-loader,
.form-field-lazy-loader {
  display: flex;
  height: 60px;
  i.fa,
  i.fas {
    display: block;
    margin: auto;
    font-size: 20px;
    color: $green2;
    width: 20px;
    height: 20px;
    line-height: 20px; } }

.form-field-lazy-loader {
  height: 40px; }

.form--sticky-submit .form-submit {
  padding: 15px 20px 15px 25px;
  position: sticky;
  bottom: 0;
  background: rgba(#fff, .8);
  border-top: 1px solid $borderLighterBlue;
  text-align: right;
  .breakpoint-under-medium & {
    bottom: 50px;
    padding: 10px 15px; } }

.form--fieldset-padding > .form-fieldset {
  padding-left: 20px;
  padding-right: 20px; }

.form--fieldset-padding .messages {
  padding: 10px 15px; }

.form--sticky-messages .messages {
  position: sticky;
  top: 60px;
  z-index: 117; }

.form-inner {
  padding: 15px 20px 0 25px; }

.contact-form {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0);
  display: flex;
  visibility: hidden;
  overflow: auto;
  padding: 20px;
  font-size: 16px;
  color: #374155;
  &.contact-form--mounted {
    transition: .3s all ease-in-out; }
  &.contact-form--open {
    visibility: visible;
    background: rgba(#000, .5); }
  .form-submit-button {
    text-align: right; } }

.contact-form-panel {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 600px;
  background: #fff;
  padding: 30px;
  box-shadow: 0 5px 20px rgba(#000, .2);
  transform: translateY(30px);
  opacity: 0;
  .contact-form--mounted & {
    transition: .3s all ease-in-out; }
  .contact-form--open & {
    transform: translateY(0);
    opacity: 1; }
  h2 {
    margin-top: 0; } }

.contact-form-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px 30px 0 0;
  a {
    text-decoration: none;
    color: $fontColor; } }

.contact-form-success {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#fff, .6);
  display: flex; }

.contact-form-success-inner {
  text-align: center;
  color: $blue;
  margin: auto;
  max-width: 260px;
  i.fa {
    font-size: 60px; } }

.form--box {
  background: #fff;
  @include shadow(6);
  border-radius: 6px;
  .messages {
    padding: 10px 15px; } }

.form-area {
  background: #fff;
  border: 1px solid $borderLighterGrey;
  border-radius: 3px;
  .form-item {
    padding: 15px;
    border-bottom: 1px solid $borderLighterGrey; }
  .form-submit {
    padding: 15px; } }
