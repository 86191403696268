$intercomOffset: 50px;

body:not(.fullscreen) .intercom-app,
body:not(.fullscreen) .intercom-lightweight-app {
  @media screen and (max-width: 601px) {
    .intercom-launcher-frame,
    .intercom-lightweight-app-launcher {
      bottom: 20px + $intercomOffset !important;
      margin-bottom: 0;
      margin-bottom: constant(safe-area-inset-bottom);
      margin-bottom: env(safe-area-inset-bottom); }
    .intercom-launcher-badge-frame {
      bottom: 60px + $intercomOffset !important;
      margin-bottom: 0;
      margin-bottom: constant(safe-area-inset-bottom);
      margin-bottom: env(safe-area-inset-bottom); } } }
