.form-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#fff, .5);
  display: flex;
  z-index: 1003;
  .fa {
    display: block;
    margin: auto;
    font-size: 30px;
    color: $orangeLight; } }
