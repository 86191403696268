.change-organization-wrapper {
  position: relative; }

.change-organization-button {
  background: none;
  padding: 0 15px 0 5px;
  border: none;
  text-align: left;
  background-image: $arrowDown;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 10px 10px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.change-organization-panel {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0 2px 12px rgba(#000, .1);
  // border: 1px solid $borderLightGrey
  width: 240px;
  display: block;
  z-index: 10;
  .breakpoint-under-small & {
    left: -40px; }
  .breakpoint-under-minimum & {
    left: -80px; } }

.change-organization-admin {
  color: #FF5733;
  font-weight: 700; }

.change-organization-panel-item {
    display: block;
    background: none;
    text-align: left;
    border: none;
    width: 100%;
    padding: 10px;
    line-height: 18px;
    border-bottom: 1px solid #f4f4f4;
    color: $fontColor;
    text-decoration: none;
    &:hover {
      background: $lightGrey;
      opacity: 1; }
    &:last-child {
      border: none; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } }

.change-organization-panel-item--current {
  background: darken(#fff, 3%); }
