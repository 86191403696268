.survey-editor-info {
  display: flex;
  .breakpoint-under-small & {
    display: block; } }

.survey-editor-info-lang {
  flex-grow: 1;
  .breakpoint-under-small & {
    margin-bottom: 10px; } }

.survey-editor-status {
  font-size: 14px;
  color: $fontColorLight;
  i.fa {
    margin-right: 5px;
    font-size: 12px; } }
