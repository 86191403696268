.course-page {
  padding: 30px 20px 60px; }

.order-intro {
  margin-top: 30px; }

.order-intro-content {
  padding: 40px;
  background: #fff;
  position: relative;
  padding-right: 230px;
  margin-right: 345px;
  min-height: 400px;
  .breakpoint-under-medium & {
    padding: 0;
    margin: 0;
    min-height: 0;
    .styled-content {
      padding: 20px; } } }

.order-intro-media--image {
  position: absolute;
  height: 100%;
  top: 0;
  left: 100%;
  margin-left: -200px;
  width: 345px + 200px;
  object-fit: contain;
  .breakpoint-under-medium & {
    position: static;
    margin: 0;
    width: 100%; } }

.order-intro-media--video {
  position: absolute;
  left: 100%;
  top: 100px;
  margin-left: -160px;
  width: 345px + 160px;
  box-shadow: 0 2px 20px rgba(#000, .1);
  .breakpoint-under-medium & {
    position: static;
    margin: 0;
    width: 100%; } }

.order-intro-selection {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  margin-right: 345px;
  padding: 0 40px 15px;
  .breakpoint-under-medium & {
    padding: 20px;
    margin-right: 0; } }


.order-intro-selection-column {
  position: relative;
  max-width: 300px;
  margin-bottom: 30px;
  height: 200px;
  &:first-child {
    margin-right: 25px; } }


.order-intro-selection-column-title {
  font-size: 24px;
  font-weight: 400; }

.order-intro-action {
  position: absolute; }

.order-breadcumb-divider {
  padding: 0 5px;
  opacity: .5; }
