.quote-placeholder {
  @extend .quote-list-cards; }

@keyframes quoteCardLoad {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.quote-placeholder-card {
  @extend .quote-list-card;
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba($lightGrey, 0) 0%, rgba($lightGrey, .5) 40%, rgba($lightGrey, 1) 50%, rgba($lightGrey, .5) 60%, rgba($lightGrey, 0) 100%);
    background-size: 200% 200%;
    animation: 2s ease-in-out infinite quoteCardLoad; } }

.quote-placeholder-card-inner {
  border-radius: 3px;
  background: $lighterGrey;
  border: 1px solid lighten($borderLighterGrey, 3%);
  height: 276px; }

.quote-placeholder-card-survey {
  display: block;
  width: 140px;
  height: 20px;
  margin: 10px auto 5px;
  background: lighten($borderLighterGrey, 3%);
  border-radius: 10px; }
