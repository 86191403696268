@import "variables";


.form-field-color--default {
  height: 60px;
  &:hover {
    background: $lighterGrey;
    .form-field-color-button i {
      opacity: .5; } }
  .form-field-color-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    border-radius: 40px; }
  .form-field-color-reset {
    font-size: 14px;
    margin: 30px 0 0 10px; } }


.form-field-color-wrapper {
  z-index: 103;
  position: relative; }

.form-field-color-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none; }

.form-field-color-picker {
  position: absolute;
  top: calc(100% - 1px);
  left: 0; }

.form-field-color-button {
  display: block;
  box-shadow: inset 0 0 0 1px rgba(#000, .1);
  border: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  i {
    mix-blend-mode: difference;
    color: #fff;
    font-size: 12px;
    opacity: .2;
    position: relative;
    top: -1px; } }


.form-field-color--inline {
  .form-field-color-input {
    display: flex; }
  .form-field-color-button {
    width: 42px;
    height: 42px;
    border-radius: 3px 0 0 3px;
    .form--simple & {
      width: 36px;
      height: 36px; } }
  input[type="text"]:not(.q-input),
  .form--simple & input[type="text"]:not(.q-input) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none; }
  .form-field-color-action {
    margin-top: 4px;
    button {
      font-size: 12px;
      font-weight: 500; } } }
