.form-wizard-wrapper {
  flex-grow: 1;
  display: flex;
  padding: 20px 0 40px;
  > .form {
    margin: auto;
    width: 100%; }
  .form-loader {
    background-color: rgba(#f4f4f4, .5);
    .fa {
      margin: 0 auto;
      position: sticky;
      top: 50%;
      width: 30px;
      height: 30px;
      color: $green2; } }
  .messages {
    position: sticky;
    top: 10px;
    max-width: 800px;
    margin: 0 auto 20px;
    z-index: 99999; } }

.form-wizard {
  .form-item-label {
    font-size: 18px;
    font-weight: 400;
    text-align: center; }
  .form-iconselect,
  .form-field--radios {
    justify-content: center; } }

.form-wizard-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  color: $fontColor;
  margin-bottom: 30px;
  text-align: center; }

.form-wizard-step {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  padding: 40px 20px;
  border-top: 1px dashed $borderLighterGrey;
  &:first-child {
    border: none;
    padding-top: 0; }
  .breakpoint-under-small & {
    padding: 20px; } }

.form-wizard-step--container .form-wizard-step-fields {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }

.form-wizard-step--full {
  max-width: 100%;
  padding: 40px 0; }

.form-item-style--wizard-field {
  padding: 0 0 15px;
  .form-item-label {
    margin-top: 20px; }
  &:first-child .form-item-label {
    margin-top: 0; }
  .form-item-input {
    background: #fff;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid $borderLighterGrey;
    box-shadow: $defaultBoxShadow; }
  .form-item-input--select {
    padding: 0;
    border: none;
    .form-field--select select {
      border-radius: 3px;
      box-shadow: none;
      background-color: #fff;
      border-color: $borderLighterGrey;
      padding: 15px;
      &:hover {
        border-color: $green2; } } }
  .form-item-input--checkboxes {
    padding: 0;
    .form-checkbox {
      padding: 10px;
      margin: 0;
      border-bottom: 1px solid $borderLighterGrey;
      &:last-child {
        border: none; }
      &:hover {
        background: $lighterGrey; } } } }

.form-wizard-submit {
  position: sticky;
  padding: 20px;
  bottom: 0;
  background: linear-gradient(0deg, rgba(#f4f4f4, 1), rgba(#f4f4f4, 0)); }

.form-wizard-submit-inner {
  .form-submit {
    justify-content: center; }
  .form-submit-content {
    display: none; } }


.form-wizard-contact {
  width: 100%;
  display: flex;
  .form {
    background: #fff;
    border: 1px solid $borderLighterGrey;
    border-radius: 3px;
    box-shadow: $defaultBoxShadow;
    .modal-content-action {
      background: #fff;
      border-top: 1px dashed $borderLighterGrey; } } }

.form-wizard-contact-inner {
  margin: auto;
  width: 100%;
  max-width: 600px; }

.form-wizard-contact-header {
  margin-bottom: 30px;
  text-align: center; }

.form-wizard-contact-title {
  @extend .form-wizard-title;
  margin-bottom: 10px; }

.form-wizard-contact-description {
  font-size: 16px;
  line-height: 20px;
  color: $fontColorLight; }

.form-wizard-contact-cta {
  padding-top: 20px; }
