/* Field */

.survey-editor-fields {
  // padding: 15px 0
  position: relative; }

.survey-editor-field {
  background: #fff;
  z-index: 32;
  border-bottom: 1px solid $borderLighterBlue;
  &:not(.survey-editor-field--fieldset):hover {
    background: $pageBackground; }
  body > &,
  body > &:hover {
    background: #fff;
    border-radius: 6px;
    @include shadow(6);
    border: none; } }

.survey-editor-field--fieldset {
  padding-bottom: 6px; }

.survey-editor-field-fieldset {
  padding: 0;
  background: $pageBackground;
  margin: 0 20px 0;
  border-radius: 6px;
  .survey-editor-field {
    background: $pageBackground; }
  .survey-editor-field:first-child {
    border-radius: 6px 6px 0 0;
    border-top: none; }
  .survey-editor-field:last-child {
    border-radius: 0 0 6px 6px;
    border-bottom: none; }
  .survey-editor-field:hover {
    background: $pageBackgroundDark; } }

.survey-editor-field-inner {
  position: relative;
  padding-left: 20px; }

.survey-editor-field-handle {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  display: flex;
  cursor: move;
  color: #d3d6dc;
  &:hover {
    color: $blue; }
  i.fa {
    display: block;
    margin: auto;
    font-size: 8px; } }

.survey-editor-field-info {
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 7px 7px 7px 0;
  font-size: 14px;
  position: relative; }

.survey-editor-field-edit {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: none;
  .survey-editor-field-info:hover & {
    display: flex; }
  span {
    margin: auto 20px auto 0;
    background: #fff;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 10px;
    padding: 6px;
    border-radius: 6px;
    color: #394760; } }

.survey-editor-field-star {
  color: $green2;
  font-size: 12px; }

.survey-editor-field-label {
  line-height: 20px;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  display: block;
  width: 100%;
  font-size: 14px;
  color: #384660;
  b {
    font-weight: 500; }
  span {
    opacity: .5;
    margin-left: 6px; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $green2; } }

.survey-editor-field-label--placeholder b {
  opacity: .5; }

.survey-editor-field-action {
  line-height: 20px;
  color: #87909f;
  display: flex;
  button {
    display: inline-block;
    padding: 0;
    background: none;
    border: none;
    margin-right: 10px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $green2; } }
  .conditions-info-items {
    margin: 0; } }

.survey-editor-field-action-left {
  flex-grow: 1; }

.survey-editor-field-remove:hover {
  color: $red; }

.survey-editor-field-type {
  margin-right: 10px;
  i.fa {
    color: $blue;
    margin-right: 2px;
    font-size: 12px; } }

.survey-editor-field-conditions {
  margin-top: 2px; }
