// When the player is "medium" and higher, display everything by default.
//
// When the player is "small", display only:
// - Play button
// - Volume Mute button
// - Progress bar
// - Subs-Caps button
// - Fullscreen button
//
// When the player is "small", display only:
// - Play button
// - Volume Mute button
// - Progress bar
// - Fullscreen button
//
// When the player is "tiny", display only:
// - Play button
// - Volume Mute button
// - Fullscreen Button
//
.video-js:not(.vjs-fullscreen) {

  &.vjs-layout-small,
  &.vjs-layout-x-small,
  &.vjs-layout-tiny {
    .vjs-current-time,
    .vjs-time-divider,
    .vjs-duration,
    .vjs-remaining-time,
    .vjs-playback-rate,
    .vjs-chapters-button,
    .vjs-descriptions-button,
    .vjs-captions-button,
    .vjs-subtitles-button,
    .vjs-audio-button,
    .vjs-volume-control {
      display: none;
    }

    // Reset the size of the volume panel to the default so we don't see a big
    // empty space to the right of the mute button.
    .vjs-volume-panel.vjs-volume-panel-horizontal:hover,
    .vjs-volume-panel.vjs-volume-panel-horizontal:active,
    .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active {
      width: auto;
      width: initial;
    }
  }

  // Hide the subs-caps button for non-Live UI "x-small" and for "tiny" players.
  &.vjs-layout-x-small:not(.vjs-liveui),
  &.vjs-layout-x-small:not(.vjs-live),
  &.vjs-layout-tiny {
    .vjs-subs-caps-button {
      display: none;
    }
  }

  // With the new Live UI, we can have the same treatment as "tiny". At
  // "x-small", the Live UI makes the progress control very small and almost
  // useless.
  &.vjs-layout-x-small.vjs-liveui,
  &.vjs-layout-tiny {

    .vjs-custom-control-spacer {
      @include flex(auto);
      display: block;
    }

    &.vjs-no-flex .vjs-custom-control-spacer {
      width: auto;
    }

    .vjs-progress-control {
      display: none;
    }
  }
}
