@function create-box-shadow($size, $color: rgba(0,0,0,.125), $multiply: false) {
  $val: 0 1px 1px $color;
  @for $i from 1 through $size {
    @if $i % 2 == 0 {
      @if $multiply == true {
        $i: $i * 2;
      }
      $val: #{$val}, 0 #{$i}px #{$i}px #{$color};
    }
  }
  @return $val;
}

@mixin shadow($size: 5, $color: rgba(#1d3a6c,.03), $multiply: false) {
  box-shadow: create-box-shadow($size, $color, $multiply);
}
