.edit-field {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($blue, .3);
  z-index: 1000;
  display: flex;
  padding: 10px;
  overflow: auto; }

.edit-field-panel {
  width: 100%;
  max-width: 700px;
  display: block;
  background: #fff;
  box-shadow: 0 2px 10px rgba(#000, .2);
  margin: auto;
  border-radius: 3px; }

.edit-field-values {
  padding: 10px 20px;
  .survey-editor-language-switcher {
    margin-bottom: 10px; }
  .form-item,
  .form-fieldset--folded .form-item {
    padding: 0;
    margin: 20px 0;
    &:last-child {
      padding: 0;
      margin-top: 0; }
    &:last-child {
      padding: 0;
      margin-bottom: 0; } }
  .form-fieldset-fields .form-item:first-child {
    margin-top: 0; }
  .form-fieldset {
    margin: 30px 0; }

  .edit-field--create & {
    padding: 10px; } }

.edit-field-step-type--default {
  padding-top: 10px;
  .survey-editor-language-switcher + & {
    padding-top: 0; } }

.edit-field-step-type > .form-fieldset:first-child {
  margin-top: 0; }

.edit-field-action {
  border-top: 1px solid $borderLightGrey;
  background: $lighterGrey;
  padding: 10px 20px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  .form-submit-content {
    padding-top: 4px;
    font-size: 14px;
    padding-left: 10px; } }


.edit-field-header {
  padding: 10px 20px;
  border-bottom: 1px dashed $borderLightGrey;
  position: relative;
  i.fa {
    color: $blue; } }

.edit-field-header-close {
  position: absolute;
  top: 12px;
  right: 10px;
  width: 36px;
  height: 36px;
  border: none;
  background: none;
  line-height: 36px;
  border-radius: 50%;
  font-size: 14px;
  padding: 0;
  color: $fontColorLight;
  &:hover {
    color: #fff;
    background: $lightGrey; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.edit-field-header-title {
  font-weight: 500;
  margin-bottom: 5px; }

.edit-field-header-info {
  color: $fontColorLight;
  font-size: 14px;
  i {
    margin-right: 6px; } }
