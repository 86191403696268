.quote-text {
  position: relative;
  display: flex;
  min-height: 200px;
  margin: 20px 20px 60px;
  .quote-video + & {
    min-height: 100px;
    .quote-text-icon {
      top: 0; } } }

.quote-text-icon {
  position: absolute;
  top: 30px;
  left: 30px;
  .breakpoint-under-small & {
    top: 0;
    left: 0;
    img {
      transform: scale(.6); } } }

.quote-text-inner {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  margin: auto;
  p {
    margin: 0 0 20px;
    &:last-child {
      margin: 0; } }
  .quote-text--small & {
    font-size: 16px;
    line-height: 20px; } }

.quote-video {}


.quote-video-inner {
  padding: 20px 20px 0;
  video {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 3px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } } }

.quote-video-download {
  padding-top: 10px;
  text-align: right; }


.quote-person {
  padding: 0 20px 20px;
  .quote-video + & {
    padding-top: 60px; } }

.quote-person-card {
  max-width: 400px;
  margin: 0 auto;
  background: #f8f8f8;
  border: 1px solid #e8e8e8;
  padding: 20px;
  text-align: center;
  border-radius: 3px;
  position: relative; }

.quote-person-image {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 50%;
  margin: -50px 0 0 -50px; }

.quote-person-image img {
  display: block;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(#000, .1);
  background: #fff; }

.quote-person-name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500; }

.quote-person-image + .quote-person-name {
  margin-top: 50px; }

.quote-person-info {
  font-size: 14px;
  line-height: 20px; }

.quote-publish-wrapper {
  text-align: center;
  padding: 0 20px 20px;
  .quote-publish {
    margin: 0 auto; } }

.quote-order-wrapper {
  text-align: center;
  padding: 0 20px 20px;
  .quote-order {
    margin: 0 auto; } }

.share-answer-action {
  text-align: center;
  padding: 20px 20px 20px;
  border-top: 1px dashed $borderLighterGrey; }

.share-answer-action-title {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 10px; }

.share-answer-button {
  display: inline-block;
  margin: 5px;
  padding: 10px 15px;
  background: $blue;
  height: 40px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  i.fab {
    margin-right: 6px; }
  &:hover {
    opacity: .8; } }

.share-answer-button--twitter {
  background: #56acee; }

.share-answer-button--linkedin {
  background: #0077b5; }

.share-answer-button--google {
  background: #efefef;
  color: $fontColor;
  i.fab {
    color: #4285f4; } }

.share-answer-action-link {
  margin-top: 15px;
  a {
    color: $fontColorLight;
    text-decoration: none;
    font-size: 14px;
    &:hover {
      color: $green2; } } }
