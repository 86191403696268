.styled-content {
  font-size: 16px;
  color: $fontColor; }

.styled-content img {
  display: block;
  max-width: 100%;
  margin-bottom: 20px; }

.styled-content h2 {
  font-size: 40px;
  line-height: 1.2em;
  font-weight: 300;
  margin: 0 0 30px;
  .breakpoint-under-medium & {
    font-size: 32px; } }

.styled-content h3 {
  font-size: 30px;
  line-height: 1.3em;
  font-weight: 400;
  margin: 0 0 20px;
  .breakpoint-under-medium & {
    font-size: 28px; } }

.styled-content p {
  margin: 0 0 20px; }

.styled-content--intro h2 + p {
  font-size: 22px;
  line-height: 30px; }

.styled-content ul {
  margin: 0 0 30px;
  padding: 0;
  li {
    list-style: none;
    position: relative;
    margin: 15px 0;
    padding-left: 20px;
    &:before {
      @extend %fa;
      content: "\f054";
      color: $orange;
      position: absolute;
      top: 0;
      left: 5px;
      font-size: 12px;
      line-height: 19px; } }
  ul {
    margin: 0 0 5px; }
  ul li {
    margin: 10px 0; }
  ul li:before {
    content: "\f111";
    font-size: 8px; }
  ul ul li:before {
    content: "\f111";
    font-size: 8px;
    color: $borderGrey; } }

.add-content {
  padding: 15px 15px;
  background: #fff;
  box-shadow: $defaultBoxShadow;
  margin-bottom: 20px;
  .message {
    margin-bottom: 15px; }
  .add-content-title {
    font-weight: 300;
    font-size: 22px;
    padding-bottom: 12px; }
  .form {
    .form-item-errors {
      margin: 0; }
    .form-submit-button {
      .button {} } } }
