.form-field--select {
  select {
    display: block;
    width: 100%;
    border-radius: 0;
    border: 1px solid $inputBorderBlue;
    line-height: 20px;
    padding: 10px;
    color: $fontColor;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: $inputBackground;
    background-image: $arrowDown;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    background-size: 12px 12px;
    border-radius: 4px;
    &:hover:not([disabled]) {
      background-color: #fff; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($inputBorderBlue, .5); }
    &:disabled,
    &:disabled:hover {
      color: #9b9b9b;
      cursor: default;
      text-decoration: none; }
    option {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    .table & {
      box-shadow: none;
      border: none;
      border-radius: 0;
      background-color: transparent; }
    .form--simple & {
      box-shadow: none;
      padding: 8px;
      font-size: 14px;
      line-height: 18px; } } }
