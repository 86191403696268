.form-iconselect {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }

.form-iconselect-item-wrapper {
  width: 25%;
  padding: 10px;
  .breakpoint-under-medium & {
    width: 50%; }
  .breakpoint-under-small & {
    width: 100%; } }

.form-iconselect-item {
  display: flex;
  width: 100%;
  box-shadow: 0 2px 6px rgba(#000, .05);
  border-radius: 3px;
  border: 1px solid $borderLighterGrey;
  background: #fff;
  display: block;
  color: $fontColor;
  padding: 10px;
  text-align: center;
  min-height: 160px;
  &:hover {
    border-color: $borderLightGrey; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &.form-iconselect-item--selected {
    border-color: $green2;
    box-shadow: 0 4px 16px rgba($green2, .3);
    &:focus {
      box-shadow: 0 0 0 2px $green2; } } }

.form-iconselect-item-inner {
  width: 100%;
  margin: auto; }

.form-iconselect-item-icon {
  margin-bottom: 15px;
  i.fa {
    color: $blue;
    font-size: 36px;
    opacity: .6;
    .form-iconselect-item:hover & {
      opacity: 1; }
    .form-iconselect-item--selected & {
      color: $green2;
      opacity: 1; } } }

.form-iconselect-item-label {
  font-size: 16px;
  line-height: 1.3em;
  word-wrap: break-word;
  font-weight: 400; }

.form-iconselect-item-description {
  margin-top: 5px;
  font-size: 14px;
  color: $fontColorLight; }
