.sendList-recipients {
  padding: 10px;
  margin: 15px 0;
  width: 100%;
  height: 100px; }

.sendList-nav {
  position: relative;
  height: 28px;
  margin: 0 0 20px; }

.sendList-nav-button--right {
  position: absolute;
  top: 0;
  right: 0; }

.sendList-title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;
  color: #0d3b70;
  display: inline; }

.sendList-info {
  padding-top: 5px;
  font-size: 14px;
  color: #9b9b9b; }

.sendList-preview {
  background: #fff;
  margin: 25px auto;
  max-width: 750px; }

.sendList-preview-footer {
  padding: 0 15px 15px 15px;
  margin: 0;
  border: 1px solid #ccc;
  border-top: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 20px;
  background: #fafafa;
  box-shadow: unset;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  p {
    margin: 0;
    color: $fontColorLight; } }


.sendList-preview-subjectpreview, .sendList-preview-messagepreview {
  padding: 15px;
  border: 1px solid #b5b5b5;
  background: #fafafa;
  border-radius: 2px;
  margin: 10px 0 20px;
  p {
    margin: 0; } }

.sendList-message {
  padding: 10px;
  max-width: 500px;
  width: 100%;
  margin: 15px 0;
  background: rgba($red, .07);
  border: 1px solid rgba($red, .4);
  color: $red; }

.sendList-message-text {
  margin-left: 5px; }

.sendList-count {
  text-align: center;
  padding: 5px;
  color: $fontColorLight;
  font-size: 14px;
  font-weight: 500; }

.sendList-statusTag {
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 2px; }

.sendList-table {
  // border: 1px solid $tableBorderColor
  border-radius: 3px;
  margin: 20px 0;
  position: relative;
  overflow: hidden;
  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background: linear-gradient(0, rgba(#fff, 1), rgba(#fff, 0));
    pointer-events: none; } }

.sendList-table-inner {
  max-height: 200px;
  overflow: auto; }


@mixin tagColor($name, $color) {
  .sendList-statusTag--#{$name} {
    background: rgba($color, .07);
    color: $color; } }

@include tagColor('green', $green);
@include tagColor('yellow', $yellow);
@include tagColor('blue', $blue);
@include tagColor('red', $red);
@include tagColor('grey', darken($grey, 50%));
