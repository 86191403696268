.welcome-page {}

.welcome-page-header {
  text-align: center;
  padding: 60px 0 40px;
  h1 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 300;
    margin: 0 0 10px; }
  p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    margin: 0; }
  .breakpoint-under-medium & {
    padding: 30px 0 20px;
    h1 {
      font-size: 30px;
      line-height: 40px; } } }

.welcome-page-description {
  padding: 20px;
  border-bottom: 1px solid $borderLighterGrey;
  p {
    margin: 0; } }

.welcome-page-panels {
  display: flex;
  width: 100%;
  .breakpoint-under-medium & {
    display: block; } }

.welcome-page-panel {
  width: 50%;
  flex: 1 0;
  padding: 30px;
  & + .welcome-page-panel {
    border-left: 1px solid $borderLighterGrey;
    .breakpoint-under-medium & {
      border-left: none;
      border-top: 1px solid $borderLighterGrey; } }
  .breakpoint-under-medium & {
    width: 100%; }
  h2 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 300;
    margin: 0 0 10px;
    text-align: center;
    .breakpoint-under-medium & {
      font-size: 20px;
      line-height: 30px; } }
  p {
    color: darken($fontColorLight, 5%);
    text-align: center; }
  .form {
    margin: 30px auto 0;
    max-width: 400px; }
  .form-submit {
    text-align: center; } }

.welcome-page-panel--content {
  display: flex; }

.welcome-page-panel-inner {
  margin: auto;
  width: 100%; }
