.quote-publish {
  display: block;
  border: none;
  background: none;
  padding: 7px 10px;
  &:focus {
    outline: none; } }

.quote-publish-inner {
  color: #000;
  display: inline-block;
  height: 22px;
  line-height: 20px;
  background: $lightGrey;
  border-radius: 13px;
  padding: 0 12px 0 9px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid $borderLighterGrey;
  min-width: 80px;
  text-align: center;
  letter-spacing: .3px;
  i.fa {
    margin-right: 5px;
    font-size: 10px;
    color: $fontColorLight;
    width: 10px;
    text-align: center; }
  .quote-publish:hover & {
    background: #fff;
    i.fa {
      color: $green2; } }
  .quote-publish:focus & {
    border-color: $yellow;
    box-shadow: 0 0 0 1px $yellow; }
  .quote-publish--published & {
    background: #fff;
    border-color: rgba($green2, .7);
    i.fa {
      color: $green2; } }
  .quote-publish--published:hover & {
    border-color: $borderLightGrey;
    i.fa {
      color: $red;
      &:before {
        content: "\f00d"; } } }
  .quote-publish--publishing &,
  .quote-publish--publishing:hover & {
    background: $lightGrey;
    border-color: $lightGrey;
    cursor: default;
    i.fa {
      margin: 0;
      color: $blue; } } }

.quote-publish-inner--green {
  background: $green2;
  border-color: darken($green2, 5%);
  color: $lightGrey;
  i.fa {
    color: $lightGrey; }
  .quote-publish:hover & {
    background: lighten($green2, 10%);
    i.fa {
      color: $lightGrey; } } }



.quote-publish--large .quote-publish-inner {
  height: 36px;
  line-height: 34px;
  border-radius: 18px;
  padding: 0 20px 0 12px;
  font-size: 14px;
  i.fa {
    font-size: 12px;
    margin-right: 8px; } }

.quote-publish--publishing.quote-publish--large .quote-publish-inner,
.quote-publish--publishing.quote-publish--large:hover .quote-publish-inner {
  padding: 0 30px;
  i.fa {
    margin: 0; } }
