.answer-view-field {
  margin-bottom: 20px; }

.answer-view-field-label {
  font-weight: 500;
  margin-bottom: 10px;
  padding: 0;
  font-size: 14px;
  .answer-view-field-label-range {
    color: $fontColorLight; } }

.answer-view-field-value {
  // border: 1px solid $borderLightGrey
  // background: $lighterGrey
  // border-radius: 2px
  // padding: 5px
  font-size: 14px; }

.answer-view-value-checkbox {
  // border: 1px solid $borderLightGrey
  margin: 6px 0;
  display: flex;
  // padding: 5px
  span {
    display: block;
    & + span {
      padding-left: 5px; } }
  &:last-child {
    margin: 0; } }

.answer-view-value-checkbox-icon {
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  border: 1px solid $borderLightGrey;
  background: $lightGrey;
  text-align: center;
  i.fa {
    font-size: 9px;
    display: none;
    width: 18px;
    height: 18px;
    line-height: 18px;
    position: relative;
    left: -1px; }
  .answer-view-value-checkbox & {
    border-color: $green2;
    background: $green2;
    color: #fff;
    i.fa {
      display: block; } } }

.answer-view-value-checkbox-icon--empty {
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  border: 1px solid $borderLightGrey;
  text-align: center;
  background: $lightGrey; }

.answer-view-value-text {
  border: 1px solid $borderLightGrey;
  background: #fff;
  border-radius: 2px;
  padding: 8px; }

.answer-view-value-image {
  img {
    max-width: 100%;
    object-fit: cover; } }

.answer-view-field-value--nps,
.answer-view-field-value--range,
.answer-view-field-value--stars {
  display: inline-block; }

.answer-view-field--customparam {
  display: flex;
  width: auto;
  margin: 0 10px 8px 0;
  line-height: 18px;
  .answer-view-field-label {
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .3px;
    width: 20px;
    color: $fontColorLight;
    font-size: 12px;
    line-height: 16px;
    padding-top: 1px; }
  .answer-view-field-value--customparam {
    padding: 0;
    border: 0;
    background: none;
    font-weight: 500; } }

.answer-view-title {
  padding: 15px 20px 5px;
  border-top: 1px dashed $borderLightGrey;
  margin-top: 15px; }

.answer-view-value-nps, .answer-view-value-range {
  @extend .answer-view-value-text;
  font-size: 20px;
  padding: 10px;
  display: inline-block;
  width: 45px;
  text-align: center; }

.answer-view-value-stars {
  border: 1px solid $borderLightGrey;
  font-size: 20px;
  padding: 10px;
  display: inline-block;
  display: flex;
  flex-direction: row; }

.answer-view-value-stars-empty {
  @extend .answer-view-value-text;
  font-size: 20px;
  padding: 10px;
  display: inline-block;
  width: 45px;
  text-align: center; }

.answer-view-value-no-opinion {
  @extend .answer-view-value-text; }

.answer-view-nps, .answer-view-range {
  // margin: 0 auto
  border: 1px solid $borderLightGrey;
  background: $lighterGrey;
  border-radius: 2px;
  // width: 80px
  // height: 80px
  text-align: center;
  padding: 5px 5px 0;
  display: inline-flex; }

.answer-view-nps-value, .answer-view-range-value {
  height: 45px;
  line-height: 45px;
  font-size: 22px;
  background: #fff;
  border: 1px solid $borderLightGrey;
  border-radius: 2px; }

.answer-view-nps-label, .answer-view-range-label {
  height: 30px;
  line-height: 30px;
  font-weight: 500;
  font-size: 14px; }

.answer-view-comment-text-value {
  position: relative;
  background: #fff;
  z-index: 4;
  border: 1px solid $borderLightGrey;
  border-radius: 3px;
  padding: 10px; }

.answer-view-comment-text-input-user {
  color: #fff;
  background: $orange;
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  z-index: 3;
  &:after {
    content: " ";
    display: block;
    width: 10px;
    height: 10px;
    background: $borderLightGrey;
    transform: rotate(45deg);
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    margin-left: -5px;
    margin-top: -10px; } }

.answer-view-comment-video {
  margin-top: 10px; }

.answer-view-value-comment-video-modal {
  padding: 15px 0; }

.answer-view-step {}

.answer-view-step--quote {}

.answer-view-step--negative-feedback {}

.answer-view-contact {
  border: 1px solid $borderLightGrey;
  border-radius: 2px;
  background: $lighterGrey;
  margin-bottom: 15px;
  font-size: 14px;
  .answer-view-field {
    display: flex;
    margin: 0;
    padding: 8px;
    border-bottom: 1px solid $borderLighterGrey;
    &:last-child {
      border: none; } }
  .answer-view-field-label {
    margin: 0;
    flex: 0 0 120px;
    line-height: 20px;
    padding-top: 4px; }
  .answer-view-field-value {
    flex: 1 0; }
  .answer-view-value-text {
    background: #fff;
    padding: 5px; } }
