.form-item-input--wysiwyg {
  .ql-container.ql-snow {
    border: none; }
  .ql-picker-label {
    border-radius: 2px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; } } }

.form-item-style--nobottomborder .quill .ql-container {
  border-bottom: none; }

.form-item-input--wysiwyg .quill {
  display: block;
  width: 100%;
  line-height: 20px;
  color: $fontColor;
  // box-shadow: inset 1px 1px 2px rgba(#000, .1)
  background: darken(#fff, 2%);
  border-radius: 2px;
  position: relative;
  border: 1px solid $borderLightGrey; }

.form-item-style--nobottomborder .quill {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: unset; }

.form-item-input--wysiwyg .ql-toolbar.ql-snow {
  background: #fff;
  border: none;
  border-bottom: 1px solid $borderLightGrey;
  position: sticky;
  top: -10px;
  z-index: 10; }

.form-item-input--wysiwyg .ql-editor {
  color: #364155;
  padding: 15px;
  h1 {
    font-size: 22px;
    line-height: 1.2;
    letter-spacing: 1px;
    margin: 0 0 5px; }
  h2 {
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 1px;
    margin: 0 0 5px; }
  h3 {
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    margin: 0 0 5px; }
  p {
    margin: 0; } }

.form-item-input--wysiwyg .ql-editor ol {
  margin: 0 0 10px;
  padding: 0; }

.form-item-input--wysiwyg .ql-editor ul {
  margin: 0 0 10px;
  padding: 0;
  li {
    counter-increment: li-count;
    list-style: none;
    position: relative;
    padding-left: 20px;
    line-height: 20px;
    margin-bottom: 3px;
    display: block;
    &:before {
      color: $yellow;
      display: block;
      position: absolute;
      top: 0;
      // left: 0
      line-height: 20px;
      height: 20px;
      width: 20px; } }
  li[class*="ql-indent"] {
    counter-reset: li-count; } }

.form-item-input--wysiwyg .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 20px;
  margin-left: 20px; }

.form-item-input--wysiwyg .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 20px;
  margin-left: 30px; }

.form-item-input--wysiwyg .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 20px;
  margin-left: 40px; }

.form-item-input--wysiwyg .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 20px;
  margin-left: 50px; }


.form-item-input--wysiwyg .ql-editor ul li:before {
  content: "\f054";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  text-align: center; }


.form-item-input--wysiwyg {
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $green2; }
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: $green2; }
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: $green2; } }
