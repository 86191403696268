.tooltip {}

.tooltip-text {
  display: none;
  font-size: 14px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(#000, .07);
  border: 1px solid $borderLighterGrey;
  border-radius: 2px;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  width: 300px;
  z-index: 10;
  .breakpoint-under-medium & {
    width: auto;
    left: 0px; }
  .tooltip:hover & {
    display: block; } }

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $borderGrey transparent transparent transparent;
  .breakpoint-under-medium & {
    visibility: hidden; } }

$tipColor: rgba(#000, .7);

.tip {
  position: relative;
  &:before {
    content: " ";
    bottom: calc(100% - 12px);
    border: 6px solid transparent;
    border-top-color: $tipColor; }
  &:after {
    content: attr(aria-label);
    bottom: 100%;
    color: #fff;
    background: $tipColor;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: .5px;
    padding: 4px 6px;
    text-shadow: 0 1px 2px rgba(#000, .1);
    box-shadow: 0 5px 6px -4px rgba(#000, .25);
    border-radius: 2px;
    white-space: nowrap; }
  &:before,
  &:after {
    pointer-events: none;
    transform: translateX(-50%) translateY(3px);
    position: absolute;
    left: 50%;
    opacity: 0;
    transition: .1s all ease-in-out;
    z-index: 20; }
  &:hover {
    &:before,
    &:after {
      opacity: 1;
      transform: translateX(-50%); } } }

.tip-bottom {
  &:before {
    bottom: auto;
    top: calc(100% - 12px);
    border-top-color: transparent;
    border-bottom-color: $tipColor; }
  &:after {
    bottom: auto;
    top: 100%; } }

.tip-bottom-left {
  &:before {
    bottom: auto;
    top: calc(100% - 12px);
    border-top-color: transparent;
    border-bottom-color: $tipColor;
    left: 50%;
    transform: translateX(-50%) translateY(3px); }
  &:after {
    bottom: auto;
    top: 100%;
    left: auto;
    right: 3px;
    transform: translateY(3px); }
  &:hover {
    &:before {
      transform: translateX(-50%) translateY(0px); }
    &:after {
      transform: translateY(0px); } } }

.tip-right {
  &:before {
    bottom: auto;
    top: 50%;
    border-top-color: transparent;
    border-right-color: $tipColor;
    left: 100%;
    transform: translateX(-15px) translateY(-50%); }
  &:after {
    bottom: auto;
    top: 50%;
    left: 100%;
    transform: translateX(-3px) translateY(-50%); }
  &:hover {
    &:before {
      transform: translateX(-12px) translateY(-50%); }
    &:after {
      transform: translatex(0px) translateY(-50%); } } }

.tip-left {
  &:before {
    bottom: auto;
    top: 50%;
    border-top-color: transparent;
    border-left-color: $tipColor;
    left: auto;
    right: 100%;
    transform: translateX(15px) translateY(-50%); }
  &:after {
    bottom: auto;
    top: 50%;
    left: auto;
    right: 100%;
    transform: translateX(3px) translateY(-50%); }
  &:hover {
    &:before {
      transform: translateX(12px) translateY(-50%); }
    &:after {
      transform: translatex(0px) translateY(-50%); } } }
