@import "variables";

.page,
html {
  background: $pageBackground; }

.page-content {
  padding-left: $navWidth;
  padding-top: $headerHeight;
  .breakpoint-under-medium & {
    padding-left: 0;
    padding-top: 50px; }
  .page--start & {
    padding-left: 0; } }

.page-content-inner {
  padding: 20px 20px 60px;
  .breakpoint-under-medium & {
    padding: 20px; }
  .breakpoint-under-small & {
    padding: 20px 0; } }

.page-content-inner--fill {
  min-height: calc(100vh - #{$headerHeight} - 78px); }

.page-content-inner--flex {
  display: flex;
  flex-direction: column;
  padding: 0;
  .page-content-header {
    padding: 20px; }
  .page-breadcrumb {
    margin: 0; } }

.page-content-inner--padded {
  padding-top: 40px; }

.page-header {
  background: #fff;
  padding: 20px; }

.page-header-title {
  font-weight: 700;
  font-size: 18px;
  color: #374155; }

.page-breadcrumb {
  font-size: 14px;
  position: fixed;
  top: 0;
  left: $navWidth;
  z-index: 14;
  padding-left: 20px;
  display: flex;
  .breakpoint-over-medium & {
 }    // background: #fff
  .breakpoint-under-medium & {
    position: static;
    padding: 0;
    margin-bottom: 20px; }
  .breakpoint-over-medium & {
    height: $headerHeight; } }

.page-breadcrumb-inner {
  line-height: 18px;
  margin: auto 0; }

.page-breadcrumb-item {
  .breakpoint-under-medium & {
    word-break: break-word; } }

a.page-breadcrumb-item {
  color: $fontColorLight;
  text-decoration: none; }

span.page-breadcrumb-item {
  color: $fontColorLight; }

.page-breadcrumb-divider {
  opacity: .5;
  margin: 0 6px; }

.page-content-header {
  padding-top: 20px;
  .breakpoint-under-medium & {
    padding-top: 0; }
  .breakpoint-under-small & {
    padding: 0 15px;
    margin-bottom: 20px; } }

.page-content-header--hidden {
  padding: 0; }

.page-content-header-inner {
  display: flex;
  margin-bottom: 20px;
  h2 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: $fontColor;
    margin: 0;
    .breakpoint-under-small & {
      font-size: 24px;
      line-height: 30px; } }
  .breakpoint-under-medium & {
    display: block;
    word-break: break-word;
 } }    // margin-bottom: 10px

.page-content-header-title {
  display: flex;
  flex-grow: 1;
  .page-content-header--center & {
    justify-content: center; }
  .breakpoint-under-medium & {
    display: block; } }

.page-content-header-action {
  display: flex;
  flex-shrink: 0;
  .button, a.button {
    margin: auto 0 auto 10px; }
  .breakpoint-under-medium & {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px dashed $borderLighterBlue;
    .button, a.button {
      margin: 0 10px 0 0; } } }

.page-content-header-tag {
  display: flex;
  padding-left: 15px;
  .breakpoint-under-medium & {
    padding: 10px 0 0; } }

.page-content-header-tag-inner {
  display: block;
  margin: auto 0;
  line-height: 20px;
  font-size: 10px;
  color: #fff;
  background: #ccc;
  text-shadow: 1px 1px 2px rgba(#000, .1);
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 6px;
  border-radius: 2px;
  letter-spacing: .3px;
  .page-content-header-tag--red & {
    background: tomato; }
  .page-content-header-tag--green & {
    background: $green2; }
  .page-content-header-tag--purple & {
    background: $purple; }
  .page-content-header-tag--blue & {
    background: $blue; } }

/* Page header search */

.page-content-header-searchsort {
  display: flex;
  .breakpoint-under-medium & {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px dashed $borderLighterGrey; } }

.page-content-header-search {
  display: flex;
  flex: 1; }

body .page-content-header-search-input {
  margin: auto 0;
  position: relative;
  width: 100%;
  // min-width: 240px
  input[type="text"]:not(.q-input) {
    display: block;
    width: 100%;
    background: none;
    // box-shadow: inset 2px 2px 2px rgba(#000, .07)
    box-shadow: none;
    border: none;
    border-radius: 20px;
    padding: 7px;
    padding-left: 34px;
    font-size: 14px;
    &:focus {
      box-shadow: none; } }
  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 100%;
    display: flex;
    color: $fontColorLight;
    pointer-events: none;
    i.fa {
      display: block;
      margin: auto;
      font-size: 12px; } } }

.page-content-header-search-input input[type="text"]:focus,
.page-content-header-search--active .page-content-header-search-input input[type="text"] {
  background: $pageBackgroundDark;
  & + label {
    color: $blue; } }

.page-content-header-search-clear {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 100%;
  display: flex;
  border: none;
  background: none;
  padding: 0;
  &:focus {
    outline: none;
    span {
      box-shadow: 0 0 0 2px $yellow; } }
  &:hover span {
    background: tomato; }
  span {
    display: block;
    margin: auto;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: $borderLightGrey;
    color: #fff;
    font-size: 10px;
    border-radius: 50%; } }

/* Sort */

.page-content-header-sort {
  margin-right: 10px;
  position: relative;
  display: flex;
  > button {
    margin: auto; } }

.page-content-header-sort-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20; }

.page-content-header-sort-list {
  position: absolute;
  top: 100%;
  background: #fff;
  z-index: 21;
  width: 200px;
  color: $fontColor;
  border: 1px solid $borderLighterBlue;
  @include shadow(6);
  border-radius: 6px;
  padding: 3px;
  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    border: 6px solid transparent;
    border-bottom-color: $borderLighterBlue; }
  .breakpoint-over-medium & {
    right: 0;
    &:after {
      right: 11px; } }
  .breakpoint-under-medium & {
    left: 0;
    &:after {
      left: 11px; } } }

button.page-content-header-sort-list-item {
  display: block;
  border: none;
  background: none;
  text-align: left;
  width: 100%;
  padding: 5px 7px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  i.fa {
    color: $fontColorLight;
    margin-right: 6px; }
  &:not(:last-child) {
    margin-bottom: 2px; }
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px $yellow; }
  &:hover {
    background: $lightGrey; } }

.page-content-header-sort-list-item--active {
  background: $lightGrey;
  i.fa {
    color: $blue; } }

.page-content-header-sort-button {
  display: block;
  width: 36px;
  height: 36px;
  border: 1px solid transparent;
  background: none;
  text-align: center;
  color: $fontColorLight;
  border-radius: 50%;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:hover {
    background: $pageBackgroundDark;
    color: $blue; } }

/* Footer */

$footerColor: rgba(desaturate($blue, 36%), .5);
$footerColorHover: rgba(desaturate($blue, 36%), .8);

.footer {
  border-top: 1px solid $borderLighterBlue;
  padding: 20px;
  font-size: 13px;
  line-height: 20px;
  color: #374155;
  display: flex;
  color: $footerColor;
  margin-top: 40px;
  .breakpoint-under-medium & {
    display: block;
    padding-bottom: 80px; }
  a {
    margin-right: 10px;
    color: $footerColor;
    &:hover {
      opacity: 1;
      color: $footerColorHover; } } }

.footer-item--contact {
  flex-grow: 1;
  // padding-left: 20px
  .breakpoint-under-medium & {
    padding: 10px 0; } }

.footer-item--terms {
  padding-left: 20px;

  .breakpoint-under-medium & {
    padding: 0 0 10px 0; } }

.footer-item--copyright {}

.footer-logo {
  height: 32px;
  display: block;
  body.theme-trustmary & {
    object-fit: contain; } }

/* Container */

.container {
  max-width: $containerWidth;
  margin: 0 auto; }

.container--columns {
  display: flex;
  @media screen and (max-width: $mediumBreakpoint) {
    display: block; } }

.container-column {
  flex-grow: 1;
  @media screen and (max-width: $mediumBreakpoint) {
    width: 100%; } }

.container-column-2 {
  width: 50%;
  @media screen and (max-width: $mediumBreakpoint) {
    width: 100%; } }

.container-column-3 {
  width: 33.33%;
  @media screen and (max-width: $mediumBreakpoint) {
    width: 100%; } }

.container-wide {
  max-width: 1400px;
  margin: 0 auto; }

.container-narrow {
  max-width: 900px;
  margin: 0 auto; }

.container-tiny {
  max-width: 600px;
  margin: 0 auto; }

/* Page view */

.page-view {
  background: #fff;
  @include shadow(6);
  border-radius: 6px;
  margin-bottom: 20px; }

.page-view-title {
  padding: 10px 15px;
  color: $fontColor;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600; }

.page-view-content {
  padding: 15px;
  .page-view-title + & {
    padding-top: 0; } }

.page-view-empty {
  @extend .page-view;
  text-align: center;
  padding: 20px;
  color: $fontColorLight; }

.page-view-header {
  padding: 15px 20px;
  // border-bottom: 1px solid #e7e7e7
  h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    margin: 0; } }

.page-view-info {
  padding: 15px;
  .fa-external-link-square-alt {
    margin-left: 6px;
    font-size: 12px;
    color: #9b9b9b; } }

.page-action {
  padding: 15px;
  border-top: 1px dashed $borderLighterBlue;
  margin-top: 40px;
  .button-plain {
    text-decoration: underline $fontColorLight;
    &:hover {
      text-decoration: underline $red; } } }

.page-action--narrow {
  padding-left: 0;
  padding-right: 0; }

.page-action--light {
  color: $fontColorLight; }

.page-id {
  font-size: 12px;
  padding-top: 10px;
  color: lighten($fontColorLight, 30%); }

.page-id--wide {
  padding-left: 15px; }

/**
 * Page cards
 */

.page-card {
  background: #fff;
  position: relative;
  @include shadow(6);
  border-radius: 6px;
  margin: 0 0 20px;
  .table .table-row > .table-cell:first-child {
    padding-left: 20px; } }

.page-card-inner {
  padding: 10px 20px 20px; }

.page-card-title {
  font-size: 18px;
  font-weight: 500;
  color: $blue;
  padding: 15px 20px 10px 20px; }

.page-card-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: $fontColorLight;
  padding: 0px 20px 5px 20px; }

.page-card-content {
  padding: 0 20px 15px; }

.page-card-content--big {
  border-top: 1px solid $borderLighterBlue;
  padding-top: 20px;
  margin-top: 15px; }

.page-content-message {
  max-width: 600px;
  margin: 40px auto 0;
  padding: 15px;
  background: #fff;
  border: 1px solid $borderLighterBlue;
  border-radius: 3px; }

.page-content-message-link {
  margin-top: 15px;
  text-align: center; }

.page-error {
  padding: 20px; }

