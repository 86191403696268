.tag {
  display: inline-block;
  margin: auto 0;
  line-height: 18px;
  font-size: 9px;
  color: #fff;
  background: #ccc;
  border: 1px solid transparent;
  // text-shadow: 1px 1px 2px rgba(#000, .1)
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 6px;
  border-radius: 3px;
  letter-spacing: .5px;
  i.fa {
    margin-right: 3px;
    margin-left: -1px;
    text-shadow: none; }
  &.tag--red {
    background: rgba(tomato, .2);
    color: darken(tomato, 5%); }
  &.tag--green {
    background: rgba($green2, .2);
    color: darken($green2, 5%); }
  &.tag--blue {
    background: rgba($blue, .2);
    color: darken($blue, 5%); }
  &.tag--darkBlue {
    background: $blue;
    color: #fff; }
  &.tag--yellow {
    background: rgba($orange, .2);
    color: darken($orange, 5%); }
  &.tag--purple {
    background: rgba($purple, .2);
    color: darken($purple, 5%); }
  &.tag--ghost {
    background: #fff;
    color: $fontColorLight;
    border-color: $borderLightBlue;
    text-shadow: none; }
  .breakpoint-under-small &,
  &.tag--small {
    line-height: 14px;
    font-size: 8px;
    padding: 0 3px; } }

a.tag, button.tag {
  &:hover {
    opacity: .8; }
  &:focus {
    box-shadow: 0 0 0 2px rgba($blue, .2); }
  &.tag--red:focus {
    box-shadow: 0 0 0 2px rgba(tomato, .07); }
  &.tag--green:focus {
    box-shadow: 0 0 0 2px rgba($green2, .07); }
  &.tag--blue:focus {
    box-shadow: 0 0 0 2px rgba($blue, .07); }
  &.tag--yellow:focus {
    box-shadow: 0 0 0 2px rgba($orange, .07); }
  &.tag--purple:focus {
    box-shadow: 0 0 0 2px rgba($purple, .07); } }

a.tag {
  text-decoration: none; }
