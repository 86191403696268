.video-player {
  video:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; } }

.video-player-alternative {
  text-align: center;
  padding-top: 15px;
  button {
    display: inline-block;
    background: none;
    border: none;
    color: $fontColorLight;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $orange; }
    &:hover {
      color: $fontColor; } } }
