.embedold {
  background: #fff;
  box-shadow: $defaultBoxShadow;
  margin-bottom: 20px; }

.embedold-top {
  padding: 15px; }

.embedold-title-wrapper {
  display: flex;
  @media screen and (max-width: $smallBreakpoint) {
    display: block;
    .embedold-title-right {
      padding-top: 15px; } } }

.embedold-title {
  font-size: 22px;
  font-weight: 500;
  flex-grow: 1;
  @media screen and (max-width: $mediumBreakpoint) {
    font-size: 18px; } }

.embedold-fields {
  display: flex;
  padding: 0 15px 15px;
  @media screen and (max-width: $mediumBreakpoint) {
    display: block; } }

.embedold-field {
  width: 25%;
  padding-right: 15px;
  @media screen and (max-width: $mediumBreakpoint) {
    width: 100%;
    padding-right: 0;
    padding-bottom: 15px; }
  &:last-child {
    padding: 0; }
  label {
    display: block;
    cursor: pointer;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 14px;
    color: $fontColor; }
  select {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: $fontColor;
    line-height: 22px;
    padding: 4px 6px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid $borderGrey;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: linear-gradient(to bottom, #fff 0%, #fff 100%);
    background-repeat: repeat;
    cursor: pointer;
    &::-ms-expand {
      display: none; }
    &:hover {
      border-color: $blue; }
    &:focus {
      border-color: $blue;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none; }
    option {
      font-weight:normal {} } }
  input[type="text"] {
    display: block;
    width: 100%;
    line-height: 22px;
    padding: 4px 6px;
    border: 1px solid $borderGrey; } }

.embedold-code {
  padding: 0 15px 15px; }

.embedold-code-inner {
  background: $lightGrey;
  border: 1px solid darken($lightGrey, 10%);
  padding: 10px 15px;
  pre {
    margin: 0;
    padding: 0;
    overflow: scroll;
    font-size: 14px;
    line-height: 18px; } }

.embedold-preview {
  padding-top: 15px;
  background: #fff;
  iframe {
    display: block;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    min-height: 300px; } }

.embedold-preview-empty {
  margin-top: 20px;
  text-align: center; }

.embedold-preview-empty-inner {
  color: $borderGrey; }

.embedold-intro {
  max-width: 700px;
  margin: 0px auto;
  font-weight: 100;
  font-size: 16px;
  text-align: center; }


/* Demo button */

.embedold-demo {
  border: 1px solid #e7e7e7;
  padding: 0 15px 0 10px;
  background: #fff;
  color: $fontColor;
  font-size: 14px;
  font-weight: 500;
  height: 26px;
  line-height: 24px;
  border-radius: 13px;
  i {
    margin-right: 8px;
    font-size: 12px; }
  &:hover {
    border-color: $blue; }
  &:focus {
    outline: none;
    border-color: $blue; } }

.embedold-demo--active i {
  color: $blue; }


/* Survey */
.embedold-surveys {
  position: relative; }

.embedold-surveys-button {
  display: block;
  width: 100%;
  line-height: 22px;
  padding: 4px 6px;
  border: 1px solid $borderGrey;
  text-align: left;
  background: #fff;
  &:focus {
    outline: none;
    border-color: $blue; } }

.embedold-surveys-list {
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  background: #fff;
  width: 250px;
  border: 1px solid $grey;
  box-shadow: 0 2px 10px rgba(#000, .1);
  display: none;
  .embedold-surveys--open & {
    display: block; } }

.embedold-surveys-list-button {
  display: flex;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  text-align: left;
  overflow: hidden;
  font-size: 15px;
  border-bottom: 1px solid $grey;
  font-size: 12px;
  &:last-child {
    border: none; }
  &:focus {
    outline: none; } }

.embedold-surveys-list-button-check {
  display: flex;
  height: 100%;
  color: rgba($fontColor, .5);
  padding-top: 2px;
  i {
    margin: auto; }
  .embedold-surveys-list-button--selected & {
    color: $fontColor; } }

.embedold-surveys-list-button-name {
  flex-grow: 1;
  padding-left: 10px; }
