
.contacts-add {
  padding: 15px 20px;
  border-top: 1px solid #e7e7e7; }

.breakpoint-over-medium .contacts-add {
  .form {
    display: inline-block;
    .form-item {
      padding: 0;
      width: 300px + 180px;
      input[type="text"] {
        padding-right: 180px; } }
    .form-item-errors {
      margin: 0; }
    .form-submit-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px;
      .button {
        display: block;
        margin: 0;
        width: 180px; } } } }

.breakpoint-under-medium .contacts-add {
  .form {
    display: block;
    .form-item {
      padding: 0 0 10px; } } }


.contacts-dropdown {
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none {
    appearance: none; }
  cursor: pointer;
  background-color: #fff;
  background-image: $arrowDown;
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) 50%;
  background-size: 12px 12px;
  border: none;
  border-radius: 2px;
  min-width: 100px;
  &:hover {
    box-shadow: 0 0 0 2px $yellow; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  &:disabled {
    color: #9b9b9b; } }
