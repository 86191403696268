@import "variables";

/* Header */

.header {
  // background: #fff
  background: rgba(#fff, .9);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 13;
  top: 0;
  left: 0;
  width: 100%;
  height: $headerHeight;
  .breakpoint-under-medium & {
    height: 50px; } }

body:not(.disable-header-shadow) .header {
  @include shadow(3, rgba($blue, .05), true); }

body.header-border-shadow .header {
  box-shadow: inset 0 -1px 0 $borderLighterBlue; }

.header-container {
  // max-width: $containerWidth
  margin: 0 auto;
  display: flex; }

.header-content {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end; }

.header-logo {
  svg {
    display: block;
    height: 32px;
    margin: auto; }
  .breakpoint-over-medium & {
    // border-right: 1px solid $borderLighterGrey
    box-shadow: inset -1px 0 rgba(#000, .1);
    padding: 12px 20px 12px 20px;
    width: 200px; }
  .breakpoint-over-medium .page--start & {
    border: none;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    box-shadow: none;
    height: $headerHeight;
    display: flex;
    a {
      margin: auto; } }
  .breakpoint-under-medium & {
    width: 120px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50px;
    height: 50px;
    padding-top: 10px;
    svg {
      height: 30px;
      width: 120px; } } }

.header-lang {
  padding: 18px 15px 18px 0; }

.header-organization {
  flex-grow: 1;
  color: $fontColor;
  font-weight: 500;
  line-height: 30px;
  font-size: 13px;
  padding: 13px 0 13px 15px; }

.header-logout {
  text-align: right;
  font-size: 14px;
  font-weight: 700;
  a {
    display: block;
    color: $blue;
    text-decoration: none;
    line-height: 30px;
    padding: 13px;
    &:hover {
      text-decoration: underline;
      text-decoration-color: $yellow; } } }

.header-feedback {
  padding-right: 15px;
  padding-top: 2px;
  a {
    background: $orange;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    padding: 5px 15px;
    border-radius: 18px;
    &:hover {
      opacity: .8; } } }
