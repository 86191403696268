input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  &:not(.q-input) {
    display: block;
    width: 100%;
    border: 1px solid $inputBorderBlue;
    line-height: 20px;
    padding: 10px;
    color: $fontColor;
    // box-shadow: inset 1px 1px 2px rgba(#000, .1)
    background: $inputBackground;
    border-radius: 4px;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($inputBorderBlue, .5); }
    &:disabled {
      color: #9b9b9b; }
    .form-input-attachments & {
      border-radius: 2px 0 0 2px; }
    .table & {
      box-shadow: none;
      border: none;
      border-radius: 0;
      background: none; }
    .form--simple & {
      box-shadow: none;
      padding: 8px;
      font-size: 14px;
      line-height: 18px; } } }

.form-input-attachments {
  display: flex;
  label {
    display: block;
    line-height: 20px;
    padding: 10px;
    border: 1px solid $inputBorderBlue;
    border-left: none;
    background: $pageBackground;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    font-weight: 500;
    color: $fontColor;
    cursor: pointer; }
  .form-item-input--number & {
    display: inline-flex; } }
