.quote-filters {
  @extend %filter-bar;
  .quote-list--placeholder & {
    pointer-events: none; }
  & + .quote-list-cards {
    margin-top: 20px; }
  .survey-page &,
  .editor-content & {
    @extend %filter-bar-tabs; } }

.quote-filters-fields {
  @extend %filter-bar-fields; }

.quote-filters-list {
  @extend %filter-bar-list; }

.quote-filters-fields {
  @extend %filter-bar-fields; }

.quote-filters-actions {
  @extend %filter-bar-actions; }

.quote-filters-search {
  @extend %filter-bar-search; }

body .quote-filters-search-input {
  @extend %filter-bar-search-input; }

.quote-filter-search-clear {
  @extend %filter-bar-search-clear; }

.quote-filters-select {
  @extend %filter-bar-select;
  // padding-right: 10px
  // label
  //   left: 5px
  // select
 }  //   padding-left: 5px

.quote-filters {
  position: sticky;
  top: 0;
  z-index: 22;
  .breakpoint-under-medium & {
    position: static; } }
