.messages {}

.message-wrapper {
  padding: 10px; }

.message {
  display: block;
  color: #fff;
  background: $blue;
  padding: 10px 14px 10px 10px;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px rgba(#000, .1), $defaultBoxShadow;
  font-size: 14px;
  line-height: 16px;
  text-shadow: 1px 1px 3px rgba(#000, .1);
  i.fa {
    color: rgba(#000, .3);
    margin-right: 6px;
    font-size: 12px; }
  a {
    color: $fontColor;
    text-decoration: underline $green2; }
  .modal-form & {
    margin-bottom: 10px; } }

.message--fixed {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9999999;
  opacity: .8;
  &:hover {
    opacity: 1; } }

.message--success {
  background: $green2; }

.message--error {
  background: tomato; }

.message--square {
  border-radius: 0; }

.message--info {}

.message-grey,
.message--grey {
  @extend .message;
  background: rgba(#d3d6dc, .5);
  border: none;
  color: $fontColor;
  box-shadow: none;
  border-radius: 6px;
  text-shadow: none;
  i.fa {
    color: $blue; } }

.message--white {
  background: #fff;
  color: $fontColor;
  box-shadow: inset 0 0 0 1px rgba(#000, .1);
  i.fa {
    color: $green2; }
  &.message--error {
    box-shadow: inset 0 0 0 1px rgba($red, .5);
    i.fa {
      color: $red; } } }

.message--icon {
  position: relative;
  padding-left: 32px;
  i.fa {
    position: absolute;
    top: 50%;
    left: 8px;
    width: 16px;
    line-height: 16px;
    margin-top: -8px; } }

.message-success {
  @extend .message;
  background: rgba($green, .1);
  border: none;
  color: darken($green, 6%);
  box-shadow: none;
  border-radius: 6px;
  text-shadow: none;
  i.fa {
    color: $green; } }

.message--warning,
.message-warning {
  @extend .message;
  background: rgba($orange, .1);
  border: none;
  color: darken($orange, 6%);
  box-shadow: none;
  border-radius: 6px;
  text-shadow: none;
  i.fa {
    color: $orange; } }
