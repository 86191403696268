.form-item-errors {
  position: absolute;
  top: 100%;
  right: 0;
  margin-right: 5px;
  z-index: 10;
  display: block;
  background: $red;
  border-radius: 2px;
  margin-top: -5px;
  pointer-events: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: -16px; }

.form-item-errors:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 6px;
  margin-top: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $red; }

.form-item-errors--left {
  right: auto;
  left: 0;
  &:after {
    right: auto;
    left: 6px; } }

.form-item-error {
  padding: 0 12px;
  line-height: 26px;
  font-size: 14px;
  color: #fff; }
