.report-table {
  background: #fff;
  box-shadow: $defaultBoxShadow;
  .breakpoint-under-small & {
    margin-bottom: 20px; } }

.report-table-top {
  display: flex;
  .breakpoint-under-small & {
    display: block;
    padding: 15px; } }

.report-table-title {
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  flex-grow: 1;
  line-height: 26px;
  .breakpoint-under-small & {
    padding: 0; } }

.report-table--empty .report-table-title {
  padding: 15px;
  text-align: center;
  color: $fontColor;
  line-height: 20px; }

.report-table-contents {
  font-size: 14px; }

.report-export {
  padding: 15px;
  .breakpoint-under-small & {
    display: none; } }

.report-export-button {
  border: 1px solid #e7e7e7;
  padding: 0 15px;
  background: #fff;
  color: $fontColor;
  font-size: 12px;
  font-weight: 500;
  height: 26px;
  line-height: 24px;
  border-radius: 13px;
  .fa {
    margin-right: 8px;
    font-size: 10px; }
  &:hover {
    border-color: $blue; }
  &:focus {
    outline: none;
    border-color: $blue; } }

.report-table-mobile-label {
  display: none;
  line-height: 20px;
  margin-bottom: 5px;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: .3px;
  color: lighten($fontColor, 10%);
  .breakpoint-under-small & {
    display: block; } }

.report-table-header-row,
.report-table-row {
  display: flex;
  border-bottom: 1px solid #f4f4f4;
  > div {
    padding: 10px;
    .breakpoint-under-small & {
      padding: 5px 10px; } }
  &:last-child {
    border: none; }
  .breakpoint-under-small & {
    display: block;
    margin: 10px;
    padding: 5px 0;
    border: 1px solid $grey;
    border-radius: 2px; } }

.report-table-header-row-item {
  flex-grow: 1; }

.report-table-header-row-item--date {
  flex-grow: 0; }

.report-table-header-row {
  border-bottom: 2px solid #f4f4f4;
  .breakpoint-under-small & {
    display: none; } }

.report-table-row:hover {
  background: darken(#fff, 2%); }

.report-table-header-row {
  font-weight: bold; }

.report-table-nps {
  width: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  .report-table-row & {
    span {
      display: inline-block;
      width: 36px;
      height: 36px;
      border: 1px solid $grey;
      border-radius: 50%;
      line-height: 36px - 2px;
      font-size: 12px;
      font-weight: 500;
      background: #fff; }
    .breakpoint-under-small & {
      display: flex;
      b {
        line-height: 36px;
        padding-right: 10px; }
      span {
        flex-shrink: 0; } } } }


.report-table-content {
  flex-grow: 1; }

.report-table-created {
  width: 200px;
  flex-grow: 0;
  flex-shrink: 0; }

.report-top {
  background: #fff;
  margin-bottom: 20px;
  box-shadow: $defaultBoxShadow;
  @media screen and (max-width: $mediumBreakpoint) {
    padding: 0 0 20px;
    display: block; } }


.report-top-info {
  flex-grow: 1;
  padding: 20px;
  border-bottom: 1px dashed $borderLighterGrey;
  .breakpoint-under-small & {
    padding: 15px; } }

.report-top-dates {
  display: flex;
  .breakpoint-under-medium & {
    display: block; } }

.report-top-nps-wrapper {
  height: 250px;
  overflow: hidden;
  width: 300px;
  position: relative;
  margin: 0 auto;
  padding-bottom: 15px;
  .breakpoint-under-small & {
    margin-top: 20px;
    width: 100%; } }

.report-top-nps {
  position: absolute;
  width: 500px;
  height: 250px;
  top: 0;
  left: 50%;
  margin-left: -250px; }

.report-top-nps-stats {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  text-align: center;
  b {
    display: block;
    line-height: 30px;
    font-size: 20px; }
  span {
    line-height: 60px;
    font-size: 50px;
    font-weight: 300;
    display: block; } }


.report-top-title {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 500;
  @media screen and (max-width: $mediumBreakpoint) {
    font-size: 18px; } }

.report-top-times {
  font-size: 14px;
  line-height: 26px;
  color: $fontColor; }

/* Stats */

.report-stats {
  font-size: 14px;
  padding-top: 10px;
  .breakpoint-under-medium & {
    display: flex;
    flex-wrap: wrap; }
  .report-top & {
    padding-top: 5px; } }

.report-stats-item {
  padding: 5px 0;
  display: flex;
  .breakpoint-under-medium & {
    width: 50%; }
  .report-top & {
    display: inline-flex;
    margin-right: 15px; } }

.report-stats-item-indicator {
  display: block;
  background: #efefef;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  // .report-stats-item--promoters &
  //   background: #5fc2ae
  // .report-stats-item--passives &
  //   background: #0d3b70
  // .report-stats-item--decorators &
  //   background: #faa61a
  .report-stats-item--promoters & {
    background: #ffc907; }
  .report-stats-item--passives & {
    background: #faa61a; }
  .report-stats-item--decorators & {
    background: #efefef; }
  .report-stats-item--total & {
    box-shadow: inset 0 0 0 3px $blue; } }

.report-stats-item-content {
  padding-left: 10px;
  line-height: 20px;
  .report-top & {
    padding-left: 5px; } }

/* Survey link */

.report-survey {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: $defaultBoxShadow;
  .breakpoint-under-small & {
    font-size: 14px;
    line-height: 1.3em; } }

.report-survey-link {
  display: flex;
  line-height: 26px;
  .breakpoint-under-small & {
    display: block; } }

.report-survey-link-label {
  span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 15px;
    .breakpoint-under-small & {
      padding-bottom: 0;
      padding-top: 10px; } } }

.report-survey-link-item {
  flex-grow: 1;
  a {
    display: block;
    padding: 15px 0;
    color: $fontColor;
    text-decoration-color: $borderGrey;
    i {
      margin-left: 6px;
      font-size: 12px;
      color: $borderGrey; }
    .breakpoint-under-small & {
      padding: 0 15px; }
    &:hover {
      text-decoration-color: $blue;
      i {
        color: $blue; } } } }

.survey-link {
  position: relative;
  overflow: hidden;
  min-width: 100px;
  text-align: center;
  display: block;
  input[type="text"],
  textarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: none;
    opacity: 0;
    cursor: pointer; }
  .breakpoint-under-small & {
    text-align: left;
    padding: 0; }
  .embed-code & {
    display: inline-block;
    text-align: left;
    padding: 0;
    margin-top: 15px; } }

.survey-link-button {
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  @extend .report-export-button;
  border-color: $borderLightGrey;
  .survey-link:hover & {
    border-color: $green2; } }

.report-survey-link-copy,
.report-survey-link-qr {
  .breakpoint-under-small & {
    padding: 10px 15px 0; } }

.breakpoint-under-small .report-survey-link-qr {
  padding-bottom: 10px; }


/* Report datepicker */

.report-datepicker {
  position: relative;
  display: inline-block;
  flex-grow: 1; }

.report-datepicker-custom {
  position: absolute;
  top: 100%;
  right: 5px;
  background: #fff;
  border: 1px solid $blue;
  border-radius: 4px;
  box-shadow: 0 2px 20px rgba(#000, .2);
  font-size: 14px;
  line-height: 18px;
  display: none;
  width: 510px;
  z-index: 999;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    right: 30px;
    bottom: 100%;
    border: 10px solid transparent;
    border-bottom-color: $blue; }
  .report-datepicker--open & {
    display: block;
    .breakpoint-under-small & {
      width: 260px;
      .rdt {
        margin: 0 auto; } } } }


.report-datepicker-custom-dates {
  display: flex;
  .breakpoint-under-small & {
    display: block; }
  .rdt + .rdt {
    border-left: 1px solid $lightGrey;
    .breakpoint-under-small & {
      border: none; } } }

.report-datepicker-button {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  @extend .report-export-button;
  &.report-datepicker-button--active {
    border-color: $blue; } }

.report-datepicker-custom-dates-confirm {
  button {
    display: block;
    width: 100%;
    background: $blue;
    border: none;
    line-height: 20px;
    padding: 10px;
    color: #fff;
    &:hover {
      opacity: .8; }
    &:focus {
      outline: none; } } }


.report-table-contact {
  display: block;
  padding: 5px 5px 5px 55px;
  position: relative;
  min-height: 50px;
  margin-top: 10px;
  border: 1px solid $grey;
  border-radius: 2px;
  background: #fff;
  > img {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover; } }

.report-table-contact-fields {
  min-height: 38px;
  display: table; }

.report-table-contact-fields-inner {
  display: table-cell;
  vertical-align: middle; }

.report-table-contact-field {
  display: inline-block;
  padding-right: 10px;
  margin: 3px 0;
  b {
    margin-right: 4px;
    line-height: 20px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .3px;
    color: lighten($fontColor, 10%); }
  i {
    margin-right: 4px;
    line-height: 20px;
    font-size: 11px;
    color: lighten($fontColor, 10%); } }

.report-table-contact-video {
  display: inline-block;
  margin-left: 10px; }

.report-table-contact-video-button {
  display: inline-block;
  background: $lightGrey;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: $blue;
  position: relative;
  padding-left: 50px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $yellow; }
  i.fa {
    margin-right: 6px;
    color: $yellow;
    font-size: 9px; } }

.report-table-contact-video-button-thumbnail {
  display: block;
  position: absolute;
  height: 40px;
  width: 40px;
  top: 50%;
  margin-top: -20px;
  left: -2px;
  border-radius: 50%;
  background: $blue;
  img {
    display: block;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%; }
  i.fa {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: -10px 0 0 -10px;
    font-size: 12px;
    color: $yellow;
    .report-table-contact-video--thumbnail & {
      opacity: 0; }
    .report-table-contact-video-button:hover & {
      opacity: 1; } } }


.report-answer-export {
  display: inline-block;
  margin-right: 10px; }

.report-answer-export-button {
  display: inline-block;
  border: 1px solid #e7e7e7;
  padding: 0 15px;
  background: #fff;
  color: $fontColor;
  font-size: 12px;
  font-weight: 500;
  height: 26px;
  line-height: 24px;
  border-radius: 13px;
  .fa {
    margin-right: 8px;
    font-size: 10px; }
  &:hover {
    border-color: $blue; }
  &:focus {
    outline: none;
    border-color: $blue; }
  .report-answer-export--exported & {
    border-color: $blue; } }

.report-table-content-footer {
  margin-top: 10px; }

.report-answer-export-share {
  display: inline-block;
  padding-left: 6px;
  button {
    border: none;
    background: none;
    color: $fontColorLight;
    i.fa {
      margin-right: 6px;
      font-size: 12px;
      color: $green2; }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $yellow; }
    &:hover {
      color: $green2; } } }


/**
 * Fields
 */

.report-fields {
  display: flex;
  flex-wrap: wrap;
  .breakpoint-under-medium & {
    display: block; } }

.report-fields-item {
  padding: 20px;
  min-width: 50%;
  flex-grow: 1;
  .breakpoint-under-medium & {
    padding: 15px; } }

.report-fields-item-label {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  max-width: 480px;
  margin: 0 auto 20px;
  .breakpoint-under-medium & {
    margin: 0 auto 10px;
    font-size: 16px; } }

.report-fields-item-values {
  display: flex;
  .report-stats {
    flex-grow: 1; } }
