.form-submit {
  display: flex; }

.form-submit--large .form-submit-button {
  button {
    line-height: 30px;
    padding: 10px 30px;
    border-radius: 25px;
    font-size: 16px; } }

.form-submit-content {
  padding-left: 10px; }

.form-submit--center {
  justify-content: center; }

.form-submit--right {
  justify-content: flex-end; }
