.dashboard {
  .breakpoint-under-small & {
    margin-top: 10px; } }

.dashboard-row {
  display: flex;
  margin: 0 -10px;
  flex-direction: row-reverse;
  .breakpoint-under-medium & {
    display: block;
    margin: 0; } }

.dashboard-box {
  background: #fff;
  border-radius: 6px;
  @include shadow();
  padding: 20px;
  margin: 0 10px 20px;
  position: relative;
  h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px;
    color: $blue; }
  .breakpoint-under-medium & {
    margin: 0 0 20px;
    padding: 20px; }
  .breakpoint-under-small & {
    margin: 0 0 20px;
    h2 {
      font-size: 16px; } } }

.dashboard-plain-box {
  margin: 0 10px 20px;
  position: relative; }

.dashboard-box-more {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $fontColorLight;
  line-height: 20px;
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  .breakpoint-under-medium & {
    position: static;
    margin: -10px 0 10px; } }

.dashboard-floating-title {
  position: absolute; }

.dashboard-loader {
  text-align: center;
  padding: 40px 20px;
  i.fa {
    color: $orange;
    font-size: 20px; } }

.dashboard-empty {
  padding: 20px 0;
  text-align: center;
  color: $fontColorLight; }

.dashboard-col {
  flex-grow: 1; }

.w--1 {
  width: 100%; }

.w--2 {
  width: 50%; }

.w--3 {
  width: 33.33%; }

.w--60 {
  width: 60%; }

.w--40 {
  width: 40%; }

.breakpoint-under-medium {
  .w--2, .w--3, .w--60, .w--40 {
    width: 100%; } }


/* Analytics */

.dashboard-analytics {
  position: relative;
  .dashboard-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background: rgba(#fff, .6);
    padding: 0;
    i.fa {
      margin: auto; } } }


.dashboard-analytics-boxes {
  display: flex;
  .breakpoint-under-medium & {
    display: block; } }

.dashboard-analytics-box {
  margin-right: 20px;
  flex-grow: 1;
  &:last-child {
    margin: 0; }
  h3 {
    margin: 0 0 5px;
    font-size: 18px;
    line-height: 22px;
    color: $blue;
    font-weight: 500;
    width: 100%;
    overflow: hidden; }
  .breakpoint-under-medium & {
    margin: 0 0 20px;
    &:last-child {
      margin: 0; } } }


.dashboard-analytics-numbers {
  display: flex; }

.dashboard-analytics-number {
  margin-right: 20px;
  &:last-child {
    margin: 0; }
  b {
    display: block;
    font-weight: 900;
    font-size: 40px;
    line-height: 1.4em; }
  span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #737475; } }

.dashboard-analytics-filters {
  padding-bottom: 10px; }

.dashboard-analytics-filter {
  @extend .report-datepicker-button;
  margin: 0 5px 5px 0; }

.dashboard-analytics-filter--active {
  border-color: $blue; }

.dashboard-analytics-info {
  margin-bottom: 10px;
  font-size: 13px;
  color: $fontColorLight; }

.dashboard-analytics-fake-data {
  background: $lightGrey;
  height: 102px; }

.dashboard-action {
  padding-top: 10px;
  text-align: center; }

.dashboard-action-more {
  color: $fontColorLight; }

/* Public answers */

.dashboard-public-answer {
  display: flex;
  margin: 20px 0; }

.dashboard-public-answer-nps {
  width: 50px;
  flex-grow: 0;
  flex-shrink: 0; }

.dashboard-public-answer-nps-circle {
  border: 1px solid $borderLightGrey;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  font-size: 14px; }

.dashboard-public-answer-content {
  flex-grow: 1;
  width: calc(100% - 50px); }

.dashboard-public-answer-image {
  img {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    object-fit: cover; } }

.dashboard-public-answer-content-inner {
  border: 1px solid $borderLightGrey;
  border-radius: 2px;
  // box-shadow: 0 1px 5px rgba(#000, .07)
  position: relative;
  &:after, &:before {
    right: 100%;
    top: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  &:after {
    border-color: rgba(#fff, 0);
    border-right-color: #fff;
    border-width: 6px;
    margin-top: -6px; }
  &:before {
    border-color: rgba($borderGrey, 0);
    border-right-color: $borderGrey;
    border-width: 7px;
    margin-top: -7px; } }

.dashboard-public-answer-content-comment {
  padding: 10px;
  font-size: 14px;
  line-height: 1.3em; }

.dashboard-public-answer-info {
  padding: 5px 120px 5px 50px;
  position: relative;
  border-top: 1px dashed $borderLightGrey;
  .breakpoint-under-small & {
    padding: 5px 20px 40px 50px; } }

.dashboard-public-answer-image {
  position: absolute;
  top: 5px;
  left: 10px; }

.dashboard-public-answer-name {
  font-weight: 500;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 14px; }

.dashboard-public-answer-name {
  padding: 5px 0;
  line-height: 20px; }

.dashboard-public-answer-publish {
  text-align: right;
  padding-top: 2px;
  position: absolute;
  top: 5px;
  right: 10px;
  .breakpoint-under-small & {
    right: auto;
    left: 0;
    top: 41px;
    width: 100%;
    .report-answer-export {
      text-align: center; } } }

.dashboard-public-answer-footer {
  padding-top: 10px;
  display: block;
  font-size: 14px;
  padding-right: 100px;
  position: relative;
  .breakpoint-under-small & {
    padding-right: 0; } }

.dashboard-public-answer-survey {
  word-break: break-word;
  a {
    color: $fontColor;
    font-weight: 500;
    text-decoration: none; } }

.dashboard-public-answer-created {
  color: $fontColorLight;
  width: 100px;
  text-align: right;
  flex-grow: 0;
  flex-shrink: 0;
  position: absolute;
  top: 10px;
  right: 0;
  .breakpoint-under-small & {
    position: static;
    width: 100%;
    margin-top: 5px;
    text-align: left; } }


/* NPS */

.dashboard-nps {
  position: relative;
  .dashboard-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(#fff, .6);
    display: flex;
    i.fa {
      display: block;
      margin: auto; } } }

.dashboard-nps-fake-data {
  height: 200px; }

.dashboard-nps-info {
  color: $fontColorLight;
  text-align: center;
  margin-top: 20px;
  font-size: 14px; }

.dashboard-nps-report {
  position: relative;
  padding-left: 270px;
  min-height: 360px;
  .report-stats {
    padding-top: 20px; }
  .report-top-nps {
    width: 400px;
    height: 200px;
    margin-left: -200px; }
  .report-top-nps-wrapper {
    width: 100%;
    height: 200px; }
  .breakpoint-under-medium & {
    padding: 0; } }

.dashboard-nps-report-left {
  width: 240px;
  padding: 20px 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  .breakpoint-under-medium & {
    width: 100%;
    padding: 0 0 20px;
    position: static; } }

.dashboard-nps-report-right {
  width: 100%;
  .report-periodical-wrapper {
    padding-left: 30px;
    .breakpoint-under-medium & {
      padding: 0; } } }

.report-periodical {
  position: relative; }

/**
 * NPS surveys filter
 */

.dashboard-nps-filters {
  padding-bottom: 20px;
  padding-left: 50px;
  margin-top: -2px;
  display: flex;
  .breakpoint-under-small & {
    padding-left: 0;
    margin-top: 40px; }
  .breakpoint-under-minimum & {
    display: block; } }

.dashboard-nps-filters-left {
  flex-grow: 1;
  .breakpoint-under-minimum & {
    padding-bottom: 10px; } }

.dashboard-nps-dates--list .dashboard-nps-filter {
  @extend .report-datepicker-button;
  margin: 0 5px 0 0;
  &:last-child {
    margin: 0; }
  .breakpoint-under-medium & {
    margin: 0 5px 5px 0; } }

.dashboard-nps-dates--list .dashboard-nps-filter--active {
  border-color: $blue; }

.dashboard-nps-dates--dropdown {
  position: relative;
  display: inline-block; }

.dashboard-nps-dates-button {
  @extend .report-datepicker-button;
  margin: 0 5px 0 0; }

.dashboard-nps-dates-list {
  @extend .language-switcher-list;
  display: block; }

.dashboard-nps-dates-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  box-shadow: 0 2px 6px rgba(#000, .07);
  border: 1px solid $borderLighterGrey;
  width: 180px;
  display: none;
  z-index: 3;
  .dashboard-nps-dates--open & {
    display: block; }
  .dashboard-nps-filter {
    display: block;
    width: 100%;
    padding: 10px;
    background: none;
    border: none;
    border-bottom: 1px solid #f4f4f4;
    text-align: left;
    font-size: 14px;
    &:hover {
      background: $lightGrey; }
    &:last-child {
      border: none; }
    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 2px $orange; }
    .breakpoint-under-minimum & {
      right: auto;
      left: 0; } } }

.dashboard-nps-dates-list--left {
  right: auto;
  left: 0; }

.dashboard-nps-surveys {
  position: relative;
  display: inline-block; }

.dashboard-nps-surveys-button {
  @extend .report-datepicker-button;
  margin: 0;
  .dashboard-nps-surveys--open & {
    border-color: $blue; } }

.dashboard-nps-surveys-groups {
  position: absolute;
  z-index: 99;
  top: -10px;
  left: calc(100% + 2px);
  background: #fff;
  box-shadow: 0 2px 6px rgba(#000, .07);
  border: 1px solid $borderLighterGrey;
  border-radius: 2px;
  display: none;
  min-width: 300px;
  .dashboard-nps-surveys--open & {
    display: block; }
  &:after, &:before {
    right: 100%;
    top: 22px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  &:after {
    border-color: rgba(#fff, 0);
    border-right-color: #fff;
    border-width: 6px;
    margin-top: -6px; }
  &:before {
    border-color: rgba($borderLightGrey, 0);
    border-right-color: $borderLightGrey;
    border-width: 7px;
    margin-top: -7px; }
  .breakpoint-under-medium & {
    left: 0;
    top: 100%;
    width: calc(1vw - 80px);
    &:after, &:before {
      display: none; } } }

.dashboard-nps-surveys-group {
  border-bottom: 1px solid $borderLighterGrey;
  &:last-child {
    border-bottom: none; } }

.dashboard-nps-surveys-group-title {
  padding: 13px 10px 5px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .3px;
  background: darken(#fff, 2%);
  border-bottom: 1px solid $borderLighterGrey; }

.dashboard-nps-surveys-group-list {}

.dashboard-nps-surveys-item {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 0;
  padding: 10px 10px 10px 30px;
  border-bottom: 1px solid $borderLighterGrey;
  font-size: 13px;
  position: relative;
  &:last-child {
    border: none; }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $orange; }
  &:hover {
    background: darken(#fff, 2%); } }

.dashboard-nps-surveys-item--clear {
  text-align: center;
  display: block; }

.dashboard-nps-surveys-item--selected {}

.dashboard-nps-surveys-item-icon {
  color: darken(#fff, 5%);
  position: absolute;
  top: 9px;
  left: 6px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  .dashboard-nps-surveys-item--selected & {
    color: $blue; } }

.dashboard-nps-surveys-item-name {
  width: 100%;
  overflow: hidden; }
