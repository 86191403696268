$icon-font-path: 'font' !default;

$text-font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;

$primary-foreground-color: #fff;
$secondary-background-color: $orange;
$secondary-background-transparency: 0.4;

$big-play-button--background: $blue;
$big-play-button--color: $orange;

$big-play-button--border-size: 0;
$big-play-button--width: 3em;
$big-play-button--line-height: 3em;
$big-play-button--height: $big-play-button--width;
$big-play-button--transparency: 0.8 !default;
